import React from "react";

function DevControl () {

    return (
        <div style={{ backgroundImage: 'url(https://wallpaperaccess.com/full/295661.jpg'}} class="flex items-center justify-center bg-gradient-to-t h-screen p-6 bg-no-repeat bg-center">
        <div class="w-full md:w-1/2 lg:w-1/3">
            <div class="flex flex-col space-y-4">
     
              
        
                <div class="flex justify-between py-6 px-4 bg-white/30 rounded-lg">
                    <div class="flex items-center space-x-4">
                        <div class="rounded-full h-14 w-14 bg-black" alt=""> </div>
                        <div class="flex flex-col space-y-1">
                            <span class="font-bold">Gigadevden</span>
                            <span class="text-sm">Pay the left 2000 PKR for the website to work</span>
                        </div>
                    </div>
                    <div class="flex-none px-4 py-2 text-stone-600 text-xs md:text-sm">
                        50m ago
                    </div>
                </div>
               
            </div>
        </div>
    </div>
    );

}


export default DevControl;