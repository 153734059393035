import React, { useEffect, useState } from 'react';

const CurrentDateDev = () => {
  const [centerIndex, setCenterIndex] = useState(2); // Start with the center card as the third card (index 2)

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  useEffect(() => {
    // Function to set the center card index based on the current day
    const setCurrentDayAsCenter = () => {
      const currentDayIndex = new Date().getDay();
      setCenterIndex(currentDayIndex);
    };

    // Set the initial center card index on component mount
    setCurrentDayAsCenter();

    // Call the function again every midnight (if you want to update the center on a new day)
    const updateCenterAtMidnight = () => {
      const now = new Date();
      const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      const timeUntilMidnight = midnight - now;
      setTimeout(() => {
        setCurrentDayAsCenter();
      }, timeUntilMidnight);
    };

    const resizeListener = () => {
      // Recalculate the center card index on window resize (optional)
      setCurrentDayAsCenter();
    };

    window.addEventListener('resize', resizeListener);

    // Clear event listeners on component unmount
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return (
    <div id="journal-scroll" className="flex justify-center rounded-lg overflow-x-scroll mx-auto pb-8 px-2 bg-white md:mx-12">
      {days.map((day, index) => {
        const cardDate = new Date();
        cardDate.setDate(cardDate.getDate() + index - centerIndex);
        const cardDay = days[cardDate.getDay()];
        const cardNumber = cardDate.getDate();

        return (
          <div
            key={day}
            className={`flex relative group hover:bg-purple-100 hover:shadow-lg hover-light-shadow rounded-lg mx-1 transition-all duration-300 cursor-pointer justify-center w-16 ${
              index === centerIndex ? 'bg-purple-300 shadow-lg light-shadow' : ''
            }`}
          >
            {index === centerIndex && (
                 <span class="flex h-3 w-3 absolute -top-1 -right-1">
                 <span class="animate-ping absolute group-hover:opacity-75 opacity-0 inline-flex h-full w-full rounded-full bg-purple-400 "></span>
                 <span class="relative inline-flex rounded-full h-3 w-3 bg-purple-500"></span>
               </span>
            )}
                
            <div className="flex items-center px-4 py-4">
              <div className="text-center">
                <p className={`text-${index === centerIndex ? 'purple-900' : 'gray-900'} text-sm transition-all duration-300`}>
                  {cardDay}
                </p>
                <p
                  className={`text-${index === centerIndex ? 'purple-900' : 'gray-900'} mt-3 ${
                    index === centerIndex ? 'font-bold' : ''
                  } transition-all duration-300`}
                >
                  {cardNumber}
                </p>
              </div>
            </div>
          </div>
        );
      })}


<style>
                    {`
  #journal-scroll {
  
    overflow-y: auto;
  }

  #journal-scroll::-webkit-scrollbar {
    height: 2px;
    width: 16px;
  }

  #journal-scroll::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 41px;
  }

  #journal-scroll:hover::-webkit-scrollbar-thumb {
    background: ; /* Change the scrollbar color to red on hover */
  }

  #journal-scroll::-webkit-scrollbar-track {
    background: transparent;
  }
`}
                </style>



    </div>
  );
};

export default CurrentDateDev;