import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import { FaExclamationTriangle } from 'react-icons/fa';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import tick from '../gif/tick.gif'

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState(['', '', '', '']);
    const [generatedCode, setGeneratedCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isCodeVerified, setIsCodeVerified] = useState(false);
    const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
    const [isEmailDisabled, setIsEmailDisabled] = useState(false);
    const [isPasswordDisabled, setIsPasswordDisabled] = useState(false);
    const refs = useRef([]);

    const generateCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let code = '';
        for (let i = 0; i < 4; i++) {
            code += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return code;
    };

    const sendCode = () => {
        const uniqueCode = generateCode();
        setGeneratedCode(uniqueCode);

        const emailParams = {
            to_email: email,
            subject: 'Password Reset OTP',
            message: `
      <div style="position: relative; text-align: center;">
        <div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); padding: 20px; background-color: rgba(0, 0, 0, 0.8); color: #fff; text-align: center; border-radius: 10px;">
          <h2 style="color: #ffffff;">Account Password Reset</h2>
          <p style="font-size: 0.875rem; line-height: 1.5; color: #ffffff;">
            <strong style="font-weight: 600;">© transknit.shop</strong>
            <svg viewBox="0 0 2 2" style="margin-right: 0.5rem; display: inline-block; width: 0.3125rem; height: 0.3125rem; fill: currentColor;" aria-hidden="true"><circle cx="1" cy="1" r="1"></circle></svg>
            One Time Password (OTP) for Account Password Reset.
          </p>
          <p style="color: white; font-size: 60px; font-weight: 800;">
  ${uniqueCode.split("").map(char => `<span style="border: 2px solid black; border-radius: 5%; padding: 10px; margin: 4px;">${char}</span>`).join("")}
</p>


        </div>
      </div>
    `
        };

        emailjs.send('service_kg9p9ld', 'template_gi1tj4l', emailParams, 'uSThYDJhrUbrMHRpa')
            .then((result) => {
                console.log(result.text);
                setIsCodeSent(true);
            }, (error) => {
                console.log(error.text);
            });
    };

    const verifyCode = () => {
        const enteredCode = code.join('');
        if (enteredCode === generatedCode) {
            setIsCodeVerified(true);
        } else {
            toast(
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FaExclamationTriangle className="mr-2 text-red-600" />
                    <span>Invalid Code</span>
                </div>,
                {
                    className: 'custom-toast-success',
                }
            );
        }
    };


    const userExists = async () => {
        setIsEmailDisabled(true); // Disable the email input
        try {
            const formData = new FormData();
            formData.append('email', email);

            const response = await fetch('https://transknit.shop/database/user-exists.php', {
                method: 'POST',
                body: formData,
            });

            const json = await response.json();

            if (json.success === true) {
                sendCode();
            } else {
                toast(
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FaExclamationTriangle className="mr-2 text-red-600" />
                        <span>User not found</span>
                    </div>,
                    {
                        className: 'custom-toast-success',
                    }
                );
                setIsEmailDisabled(false); // Enable the email input if user is not found
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsEmailDisabled(false); // Enable the email input if there's an error
        }
    };


    const updatePassword = async () => {

        if (newPassword !== confirmPassword) {
            toast(
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FaExclamationTriangle className="mr-2 text-red-600" />
                    <span>Passwords do not match</span>
                </div>,
                {
                    className: 'custom-toast-success',
                }
            );
            return;
        }

        setIsPasswordDisabled(true); // Disable the email input
        try {
            const formData = new FormData();
            formData.append('email', email);
            formData.append('newPassword', newPassword);

            const response = await fetch('https://transknit.shop/database/update-password.php', {
                method: 'POST',
                body: formData,
            });

            const json = await response.json();

            if (json.success === true) {
                setIsPasswordUpdated(true);
            } else {
                toast(
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FaExclamationTriangle className="mr-2 text-red-600" />
                        <span>User not found</span>
                    </div>,
                    {
                        className: 'custom-toast-success',
                    }
                );
                setIsPasswordDisabled(false); // Enable the email input if user is not found
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsPasswordDisabled(false); // Enable the email input if there's an error
        }
    };



    const handleInputChange = (index, value) => {
        const updatedCode = [...code];
        updatedCode[index] = value.toUpperCase();
        setCode(updatedCode);

        // Focus on the next input field if it exists
        if (refs.current[index + 1]) {
            refs.current[index + 1].focus();
        }
    };

    return (
        <div>
            <Header />
            <div className="flex items-center justify-center min-h-screen">
                {!isCodeSent ? (
                    <div>
                        <div className="mb-4 text-center">
                            <h2 className="text-3xl font-bold text-gray-800 mb-2">Reset Password</h2>
                            <p className="text-sm text-gray-600 mb-4">Enter email address to reset password..</p>
                        </div>
                        <div className="flex items-center justify-center flex-col mt-8">
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                                className="w-full border p-2 rounded"
                                required
                                disabled={isEmailDisabled}
                            />
                            <button
                                onClick={userExists}
                                className="bg-sky-500 text-white p-2 rounded hover:bg-sky-600 mt-2">
                                Send Code
                            </button> </div>

                    </div>
                ) : !isCodeVerified ? (
                    <div className="flex items-center justify-center flex-col">
                        <div className="mb-4 text-center">
                            <h2 className="text-3xl font-bold text-gray-800 mb-2">Check your inbox</h2>
                            <p className="text-sm text-gray-600 mb-4">We've sent you a verification code to <span className="font-medium text-sky-600">{email}</span>.</p>
                        </div>
                        <div className="flex space-x-4">
                            {code.map((value, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    value={value}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    className="w-16 h-16 text-4xl font-black border-gray-300 border rounded-md text-center font-urbanist"
                                    ref={(input) => refs.current[index] = input}
                                />
                            ))}
                        </div>
                        <button
                            onClick={verifyCode}
                            className="bg-sky-500 text-white p-2 rounded hover:bg-sky-600 mt-4 px-6 font-medium">
                            Verify
                        </button>
                    </div>
                ) : !isPasswordUpdated ? (
                    <div className="flex items-center justify-center flex-col">
                        <div className="mb-4 text-center">
                            <h2 className="text-3xl font-bold text-gray-800 mb-2">Update Password</h2>
                            <p className="text-sm text-gray-600 mb-4">Create a new password for <span className="font-medium text-sky-600">{email}</span>.</p>
                        </div>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            placeholder="New Password"
                            className="w-full border p-2 rounded mt-2"
                            required
                        />
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Confirm Password"
                            className="w-full border p-2 rounded mt-2"
                            required
                        />
                        <button
                            onClick={updatePassword}
                            className="bg-sky-500 text-white p-2 rounded hover:bg-sky-600 mt-4">
                            Update Password
                        </button>
                    </div>
                ) : (
                    <div className="flex items-center justify-center flex-col">
                        <div className="mb-4 text-center">
                            <img src={tick} alt="Success GIF" className="w-48 mx-auto" />
                            <h2 className="text-3xl font-bold text-gray-800 mb-2">Password Updated</h2>
                            <p className="text-sm text-gray-600 mb-4">Password successfully updated for <span className="font-medium text-sky-600">{email}</span>.</p>
                        </div>
                    </div>

                )}
            </div>
            <Footer />

            <ToastContainer position="top-right" autoClose={3000} hideProgressBar />

        </div>
    );
};

export default ForgotPassword;
