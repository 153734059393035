import React, { useState, useEffect } from "react";
import { FaCartArrowDown, FaShoppingBag } from "react-icons/fa";
import { Link, useLocation } from 'react-router-dom'
import { FaSignOutAlt } from 'react-icons/fa';
import transknit from '../images/transknit.png'

const Header = () => {

  const websitePrefix = 'www.transknit.shop';

  const [publicLogin, setPublicLogin] = useState('');
  const [name, setName] = useState('');


  const getFirstUppercaseLetter = (str) => {
    return str.charAt(0).toUpperCase();
  };

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setPublicLogin(localStorage.getItem(`${websitePrefix}-publiclogin`));
    setName(localStorage.getItem(`${websitePrefix}-publicname`));

    if (localStorage.getItem(`${websitePrefix}-current`) === null) {
      localStorage.setItem(`${websitePrefix}-current`, '0');
      setIsChecked(false);
    }


    // Check the current hash in the URL
    const hash = window.location.hash;


    if (hash === '#/transknit-attire') {
      localStorage.setItem(`${websitePrefix}-current`, '0');
      setIsChecked(false);
    } else if (hash === '#/transknit-home') {
      localStorage.setItem(`${websitePrefix}-current`, '1');
      setIsChecked(true);
    } else if (hash === '#/transknit-attire/products') {
      localStorage.setItem(`${websitePrefix}-current`, '0');
      setIsChecked(false);
    } else if (hash === '#/transknit-home/products') {
      localStorage.setItem(`${websitePrefix}-current`, '1');
      setIsChecked(true);
    } else {
      if (localStorage.getItem(`${websitePrefix}-current`) === '1') {
        setIsChecked(true);
      }
      else if (localStorage.getItem(`${websitePrefix}-current`) === '0') {
        setIsChecked(false);
      }
    }


    if (localStorage.getItem(`${websitePrefix}-current`) === '1') {
      setPage('/transknit-home')
    }
    else if (localStorage.getItem(`${websitePrefix}-current`) === '0') {
      setPage('/transknit-attire')
    }



  }, []);






  const [isMenuOpen, setMenuOpen] = useState(false);



  const handleLogout = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeOverlay = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handlePublicLogout = () => {
    localStorage.setItem(`${websitePrefix}-publiclogin`, null);
    localStorage.setItem(`${websitePrefix}-publicid`, null);
    localStorage.setItem(`${websitePrefix}-publicname`, null);
    localStorage.setItem(`${websitePrefix}-publicemail`, null);
    localStorage.setItem(`${websitePrefix}-publicpassword`, null);
    localStorage.setItem(`${websitePrefix}-publicphone`, null);
    localStorage.setItem(`${websitePrefix}-publicaddress`, null);
    closeOverlay();
    setPublicLogin(null);
  };



  const handleToggle = () => {
    setIsChecked(!isChecked);
    console.log(isChecked ? 'Transknit' : 'Transnit Home');



    const navigateAfterDelay = () => {
      setTimeout(() => {
        // Check the current hash in the URL
        const hash = window.location.hash;
        if (hash === '#/transknit-attire') {
          window.location.href = '/#/transknit-home'; // Navigate to /#/transknit-home
        } else if (hash === '#/transknit-home') {
          window.location.href = '/#/transknit-attire'; // Navigate to /#/transknit
        }
        else if (hash === '#/transknit-attire/products') {
          window.location.href = '/#/transknit-home/products'; // Navigate to /#/transknit-home/products
        } else if (hash === '#/transknit-home/products') {
          window.location.href = '/#/transknit-attire/products'; // Navigate to /#/transknit-attire/products
        } else {

          if (localStorage.getItem(`${websitePrefix}-current`) === '0') {
            localStorage.setItem(`${websitePrefix}-current`, '1');
            window.location.href = '/#/transknit-home'; // Navigate to /#/transknit-home
          } else if (localStorage.getItem(`${websitePrefix}-current`) === '1') {
            localStorage.setItem(`${websitePrefix}-current`, '0');
            window.location.href = '/#/transknit-attire'; // Navigate to /#/transknit
          }
        }
      }, 500); // 1500 milliseconds (1.5 seconds) delay
    };

    navigateAfterDelay(); // Call the navigation function initially

    // Optional cleanup: Clear timeout if component unmounts before timeout completes
    return () => clearTimeout(navigateAfterDelay);

  };


  useEffect(() => {

  }, []); // useEffect dependency on isChecked




  const [page, setPage] = useState('');

  useEffect(() => {



  }, []);



  const [isOpen, setIsOpen] = useState(false);

  const handleToggleSidebar = () => {
      setIsOpen(!isOpen);
  };

  const [isOpen2, setIsOpen2] = useState(false);

  const openConsole = (event) => {
      event.preventDefault();
      window.location.href = "/console-home";
    };


  return (
    <header className="font-poppins">
      <nav class="bg-transparent bg-opacity-0 px-6 md:px-20 lg:px-6">
        <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <div className="flex flex-row items-center space-x-8">
          


            {/* sidebar */}
            <div className="block relative z-50 lg:hidden">
            
            <button className="flex items-center px-2 py-2 text-gray-900 border-teal-400 hover:text-red-500 lg:hidden" onClick={handleToggleSidebar}>
                <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <title>Menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-6z" />
                </svg>
            </button>
            {isOpen && (
                <div className="absolute left-0 mt-2 w-52 p-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                   
                       
                        {/* <p className="block px-4 py-2 text-sm text-gray-700" role="menuitem">
                            Services
                        </p>
                        <ul>
                            <li>
                            <a href="#" className="ml-5 rounded-tl-lg bg-red-500 block px-4 py-2 text-sm text-white hover:bg-gray-100 hover:text-gray-900 hover:ml-10" role="menuitem">
                            GameDen
                           </a>
                           <a href="#" className="ml-5 bg-red-500 block px-4 py-2 text-sm text-white hover:bg-gray-100 hover:text-gray-900 hover:ml-10" role="menuitem">
                            Edulabour
                           </a>
                           <a href="#" className="ml-5 rounded-bl-lg bg-red-500 block px-4 py-2 text-sm text-white hover:bg-gray-100 hover:text-gray-900 hover:ml-10" role="menuitem">
                            Piclet
                           </a>
                            </li>
                        </ul> */}
                 
                        <ul>
                        <li>
                <Link to={`${page}`} class="text-sm block py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Home</Link>
              </li>
              <li>
                <Link to={`${page}/products`} class="text-sm flex flex-row py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Products
                  <div class="ml-2 rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white block">New</div>
                </Link>
              </li>
              <li>
                <Link to="/about" class="text-sm block py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">About us</Link>
              </li>
              <li>
                <Link to="/contact" class="text-sm block py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Contact</Link>
              </li>

              <div className="flex items-center justify-center py-4">
                <label htmlFor="toggle" className="flex items-center cursor-pointer">
                  {/* Left Side Label */}
                  <span className="text-gray-700 text-sm">Attire</span>
                  {/* Toggle Switch */}
                  <div className="relative mx-4">
                    <input
                      type="checkbox"
                      id="toggle"
                      className="sr-only"
                      checked={isChecked}
                      onChange={handleToggle}
                    />
                    <div className={`block border border-gray-400 ${isChecked ? 'bg-white' : 'bg-white'} w-14 h-8 rounded-full`}></div>
                    <div className={`dot absolute left-1 top-1 bg-sky-500 w-6 h-6 rounded-full transition transform ${isChecked ? 'translate-x-full' : ''}`}></div>
                  </div>
                  {/* Right Side Label */}
                  <span className="text-gray-700 text-sm">Home</span>
                </label>
              </div>
                        </ul>
                      

                        
                        {/* <a
                            href="/barters"
                            className="mt-5 mr-10 rounded-tr-md rounded-br-md bg-black block px-4 py-2 text-sm text-white hover:bg-red-500 md:hidden"
                            role="menuitem"
                        >
                            Barters
                        </a> */}
                        {/* <form method="POST" action="#">
                            <button type="submit" className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                                Sign in
                            </button>
                        </form> */}
                        
                    </div>
                </div>
            )}
        </div>
            {/* sidebar */}




            <Link to="/" class="flex items-center">
              <img src={transknit} class="mr-3 h-32 sm:h-32" alt="Flowbite Logo" />
            </Link>
          </div>

          <div class="flex items-center lg:order-2 border-l-2 pl-4">
            <Link to="/cart">
              <FaShoppingBag className="text-gray-950 w-6 h-6" />
            </Link>
            <div className="flex space-x-4">
              {publicLogin !== '1' ? (
                <div className="flex space-x-2">
                  {/* <button onClick={handleSignUp} className="ml-4 bg-transparent duration-300 text-sm text-sky-700 font-semibold py-2 px-4 hover:border-transparent rounded">
                  Sign Up
                </button> */}
                  <Link to="/login" className="duration-300 bg-neutral-950 text-xs text-white font-bold px-4 py-2 rounded-full ml-4">
                    Login
                  </Link>
                </div>

              ) : (

                <div className="container mx-auto flex items-center justify-between px-4">
                  <div className="flex flex-row items-center justify-between">

                    <Link to="/profile">
                    <div className="rounded-full bg-gray-950 text-white shadow w-8 h-8 flex items-center justify-center relative ml-4 font-bold">
                      {/* Add your avatar image or icon here */}
                      {/* <img
                      className="rounded-full object-cover object-center"
                      src={`https://gigadevden.com${image}`}
                      alt="public-profile-icon"
                      style={{ width: '100%', height: '100%' }}
                    /> */}
                      {getFirstUppercaseLetter(name)}
                    </div>
                    </Link>

                    {/* <div class=" bg-white flex flex-col justify-center">
<div class="flex items-center justify-center">
  <div class=" relative inline-block text-left dropdown z-10">
    <span class="rounded-md shadow-sm"
      ><button class="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800" 
       type="button" aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items-117">
        <span>Options</span>
        <svg class="w-5 h-5 ml-2 -mr-1" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
        </button
    ></span>
    <div class="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95">
      <div class="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none" aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items-117" role="menu">
        <div class="px-4 py-3">         
          <p class="text-sm leading-5">Signed in as</p>
          <p class="text-sm font-medium leading-5 text-gray-900 truncate">tom@example.com</p>
        </div>
        <div class="py-1">
          <a href="javascript:void(0)" tabindex="0" class="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"  role="menuitem" >Account settings</a>
          <a href="javascript:void(0)" tabindex="1" class="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"  role="menuitem" >Support</a>
          <span role="menuitem" tabindex="-1" class="flex justify-between w-full px-4 py-2 text-sm leading-5 text-left text-gray-700 cursor-not-allowed opacity-50" aria-disabled="true">New feature (soon)</span>
          <a href="javascript:void(0)" tabindex="2" class="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left" role="menuitem" >License</a></div>
        <div class="py-1">
          <a href="javascript:void(0)" tabindex="3" class="text-gray-700 flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"  role="menuitem" >Sign out</a></div>
      </div>
    </div>
  </div>
</div>              
</div>


    <style>
                    {`
 .dropdown:focus-within .dropdown-menu {
  opacity:1;
  transform: translate(0) scale(1);
  visibility: visible;
}
`}
                </style> */}
                    

                    <button
                      className="flex items-center text-neutral-950 focus:outline-none ml-4 p-2 rounded"
                      onClick={handleLogout}
                    >
                      <div className="flex flex-row ">
                        {/* <div className="dots w-1 h-1 bg-black dark:bg-black rounded-full"></div>
                      <div className="dots w-1 h-1 bg-black dark:bg-black rounded-full ml-[2px]"></div>
                      <div className="dots w-1 h-1 bg-black dark:bg-black rounded-full ml-[2px]"></div> */}
                        <FaSignOutAlt className="text-gray-950" />
                      </div>
                    </button>

                    {/* {isMenuOpen && (
                    <ul className="absolute right-0 top-full mt-2 w-48 bg-white rounded shadow-lg py-2">
                      <li className="py-1 px-4 hover:text-gray-700 text-gray-500 cursor-pointer">Profile</li>
                      <li className="py-1 px-4 hover:text-gray-700 text-gray-500 cursor-pointer">Settings</li>
                      <li className="py-1 px-4 hover:text-gray-700 text-gray-500 cursor-pointer">Logout</li>
                    </ul>
                  )} */}

                    {isMenuOpen && (
                      <div
                        className="overlay"
                        style={{
                          position: 'fixed',
                          top: 110,
                          right: 40,
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-end',
                          zIndex: 9998,
                          opacity: isMenuOpen ? 1 : 0,
                          transition: 'opacity 0.5s ease-in-out',
                        }}
                      >
                        <div
                          className="overlay-content"
                          style={{
                            backgroundColor: 'black',
                            padding: '20px',
                            borderRadius: '16px',
                            maxWidth: '400px'
                          }}
                        >
                          <div className="flex pb-3 items-center">
                            <div className="-ml-1 text-gray-200">
                              <FaSignOutAlt />
                            </div>
                            <p className="text-lg text-gray-100 font-semibold pl-2">Confirm logout</p>
                            <button
                              className="close-overlay"
                              onClick={closeOverlay}
                              style={{
                                marginLeft: 'auto',
                                cursor: 'pointer',
                                color: 'white'
                              }}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                              </svg>
                            </button>
                          </div>
                          <p className="text-sm text-gray-200 pb-3 font-normal mb-2">Do you really want to logout?</p>
                          <button
                            onClick={() => handlePublicLogout()}
                            className="delete-account-btn focus:outline-none bg-red-400 transition duration-150 ease-in-out hover:bg-red-500 rounded text-white px-3 py-2 text-xs"
                          >
                            Logout
                          </button>
                        </div>
                      </div>
                    )}


                  </div>
                </div>

              )}


            </div>
            {/* <Link to="/login" class="text-white hover:bg-gray-50 focus:ring-4 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none focus:ring-gray-800">Log in</Link> */}

            {/* <a href="#" class="text-white bg-gray-900 hover:bg-gray-950 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 focus:outline-none ">Get started</a> */}
            {/* <button data-collapse-toggle="mobile-menu-2" type="button" class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
                        <span class="sr-only">Open main menu</span>
                        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                        <svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </button> */}

          </div>
          <div class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
            <ul class="flex flex-col items-center mt-4 font-normal lg:flex-row lg:space-x-8 lg:mt-0 text-sm">
              {/* <li>
                            <a href="#" class="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white" aria-current="page">Home</a>
                        </li> */}
              <li>
                <Link to={`${page}`} class="block py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Home</Link>
              </li>
              <li>
                <Link to={`${page}/products`} class="flex flex-row py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Products
                  <div class="ml-2 hidden rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white sm:block">New</div>
                </Link>
              </li>
              <li>
                <Link to="/about" class="block py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">About us</Link>
              </li>

              {/* <div>
                <div class="flex items-center justify-center py-4">
                  <div class="flex items-center">
                    <input type="radio" id="transknit" name="transit_option" class="form-radio h-4 w-4 text-pink-600" />
                    <label for="transknit" class="ml-2 text-gray-700 font-semibold">Transknit</label>
                  </div>
                  <div class="mx-4 border-l border-gray-300 h-6"></div>
                  <div class="flex items-center">
                    <input type="radio" id="transnit_home" name="transit_option" class="form-radio h-4 w-4 text-pink-600" />
                    <label for="transnit_home" class="ml-2 text-gray-700 font-semibold">Transnit Home</label>
                  </div>
                </div>

              </div> */}


              <div className="flex items-center justify-center py-4">
                <label htmlFor="toggle" className="flex items-center cursor-pointer">
                  {/* Left Side Label */}
                  <span className="text-gray-700 font-semibold font">Attire</span>
                  {/* Toggle Switch */}
                  <div className="relative mx-4">
                    <input
                      type="checkbox"
                      id="toggle"
                      className="sr-only"
                      checked={isChecked}
                      onChange={handleToggle}
                    />
                    <div className={`block border border-gray-400 ${isChecked ? 'bg-white' : 'bg-white'} w-14 h-8 rounded-full`}></div>
                    <div className={`dot absolute left-1 top-1 bg-sky-500 w-6 h-6 rounded-full transition transform ${isChecked ? 'translate-x-full' : ''}`}></div>
                  </div>
                  {/* Right Side Label */}
                  <span className="text-gray-700 font-semibold">Home</span>
                </label>
              </div>
              {/* <li>
                            <a href="#" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Team</a>
                        </li>
                        <li>
                            <a href="#" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Contact</a>
                        </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;