import React , {useEffect, useState} from 'react'
import Header from '../partials/Header';
import Footer from '../partials/Footer';

import eventPoster1 from '../images/event2.png'
import eventPoster2 from '../images/event3.png'
import eventPoster3 from '../images/event1.png'
import food from '../images/temp1.jpeg'
import pizza from '../images/pizza-pizza-logo-png-transparent.png'
import forSmall from '../images/kurti.png'

import { Link } from 'react-router-dom'


import mainPoster from '../images/mainPosterAttire.jpg'


import topBurger from '../images/food_banner_25.png'

import Ethnic from '../images/ethnic.png'
import Bridal from '../images/bridal.png'

const DiagonalDialog = ({ showModal, setShowModal }) => {
  // Add event listener to prevent scrolling when modal is open
  React.useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [showModal]);

  return (
    <>
      {showModal && (
        <div className="fixed inset-0 z-40 flex items-center justify-center bg-white bg-opacity-50">
          <div className="relative w-11/12 max-w-lg h-80 bg-transparent rounded-lg overflow-hidden transform transition-transform duration-500 ease-in-out scale-95 sm:h-96">
            <div className="absolute inset-0 w-full h-full bg-transparent bg-opacity-90 transform -skew-y-6"></div>
            <div className="absolute inset-0 w-full h-full bg-gradient-to-tr from-fuchsia-500 to-sky-500 transform skew-y-6"></div>

            <div className="relative z-10 flex justify-between items-center h-full">
              <Link to="/transknit-attire" className="flex-1 h-full">
                <div
                  className="flex items-center justify-center h-full text-gray-700 font-semibold bg-transparent hover:bg-gray-600 transition-colors duration-300 sm:text-lg"
                  onClick={() => console.log('Open Transknit')}
                >
                  <div className="flex flex-col items-center bg-opacity-25 font-bold font-urbanist text-xl text-white z-10 py-2.5 px-5 mr-2 bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    Attire
                    <span className="text-sm font-medium">Designer Clothes</span>
                  </div>
                  <img
                    src="https://user-images.githubusercontent.com/2805249/64069899-8bdaa180-cc97-11e9-9b19-1a9e1a254c18.png"
                    alt="Icon"
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-60 h-60 opacity-30"
                  />
                </div>
              </Link>

              <Link to="/transknit-home" className="flex-1 h-full">
                <div
                  className="flex items-center justify-center h-full text-white font-semibold bg-transparent hover:bg-gray-600 transition-colors duration-300 sm:text-lg relative"
                  onClick={() => console.log('Open Transknit Home')}
                >
                  <div className="flex flex-col items-center bg-opacity-25 font-bold font-urbanist text-xl text-white z-10 py-2.5 px-5 mr-2 bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-gray-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                    Home
                    <span className="text-sm font-medium">Hand crafted goods</span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};




function Home()
{
    

    const imageText = [
        'Burger new Arrivals',
        '50% OFF',
        'World Animal Day',
        // Add more text values as needed
      ];
    
      const imageDate = [
        '26',
        '15',
        '4',
        // Add more text values as needed
      ];
    
      const imageDescription = [
        'Zinger Stacker is Waiting for you',
        'Get the best Discount!',
        'WorldAnimal Day Deals coming out soon',
        // Add more text values as needed
      ];


      
  const images = [
    eventPoster1,
    eventPoster2,
    eventPoster3,
    // Add more image URLs as needed
  ];



  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change the interval time as desired (in milliseconds)

    return () => clearInterval(interval);
  }, []);

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };


  const [showModal, setShowModal] = useState(true);


  const ProductCard = ({ category, name, price, bgColor, image, priceTextColor }) => (
    <div className="flex w-[300px] h-[360px]">


    <div className={`flex-shrink-0 my-6 relative ${bgColor} rounded-lg max-w-xs shadow-lg`}>
      <svg
        className="absolute bottom-0 left-0 mb-8"
        viewBox="0 0 375 283"
        fill="none"
        style={{ transform: 'scale(1.5)', opacity: 0.1 }}
      >
        <rect
          x="159.52"
          y="175"
          width="152"
          height="152"
          rx="8"
          transform="rotate(-45 159.52 175)"
          fill="white"
        />
        <rect
          y="107.48"
          width="152"
          height="152"
          rx="8"
          transform="rotate(-45 0 107.48)"
          fill="white"
        />
      </svg>
      <div className="relative pt-10 px-10 flex items-center justify-center">
        <div
          className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
          style={{
            background: 'radial-gradient(black, transparent 60%)',
            transform: 'rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1)',
            opacity: 0.2,
          }}
        ></div>
        <img className="relative w-40" src={image} alt={name} />
      </div>
      <div className="relative text-white px-6 pb-6 mt-6">
        <span className="block opacity-75 -mb-1">{category}</span>
        <div className="flex justify-between">
          <span className="block font-semibold text-xl">{name}</span>
          <span className={`block bg-white rounded-full ${priceTextColor} text-xs font-bold px-3 py-2 leading-none flex items-center`}>
            {price}
          </span>
        </div>
      </div>
    </div>
    </div>
  );


    return(
       
    
          <div>
                <Header/>

<div className="mx-4 md:mx-16 flex flex-row space-x-4 h-[450px] md:h-[750px] md:mb-16">





<div className="w-full xl:w-2/3 h-full">
  {/* <img src={background} className='w-full p-16 rounded-lg opacity-30'/> */}

  <div className="relative h-full md:h-2/3 w-full z-30 p-2">
    {/* <div className='absolute w-full h-full bg-black rounded-2xl opacity-60' >
    </div> */}
    <div className='absolute px-4 md:px-16 lg:mr-12 md:mt-16' >


      <div class="row py-8">
        <div class="col-xl-4 col-lg-5 col-sm-7 mx-lg-0 mx-auto">
          <div class="border-radius-xl mb-3 position-relative">
            <span class="mask bg-dark border-radius-xl z-index-1 opacity-5"></span>
            <div class="d-flex align-items-center z-index-2 position-relative">
              <div class="text-gray-900 icon-move-right text-sm ms-2 w-100 d-flex align-items-center">
                <div className="flex flex-row">
                  <span class="rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white">new</span>
                  <span className="ml-2">Summer Collection</span>
                </div>
                {/* <i class="fas fa-chevron-right text-xs ms-auto me-2" aria-hidden="true"></i> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 class="sm:mt-16 md:mt-0 mt-40 hidden sm:block sm:mx-auto sm:w-10/12 md:w-2/3 font-black text-4xl text-center sm:text-5xl md:text-6xl lg:w-auto lg:text-left xl:text-7xl text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-cyan-400">Wear in Style <br class="lg:block hidden"/> 
                    {/* <span class="relative text-transparent bg-clip-text bg-gradient-to-r from-red-600 to-red-500" style={{ WebkitTextStroke: '1px black' }}>Order now</span> */}
                    <span class="relative text-transparent" style={{ WebkitTextStroke: '1px black' }}>Order now</span>
                    .</h1>

      {/* <h1 className="sm:mx-auto sm:w-10/12 font-black text-2xl text-center sm:text-5xl md:text-4xl lg:w-auto lg:text-left lg:text-5xl text-white mt-40 sm:mt-0">Creative Market, develop<br className="lg:block hidden" /> <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-cyan-300">with the community</span>.</h1> */}
      <div className="hidden sm:block relative mt-4 space-y-8 lg:w-11/12">
        {/* <p className="sm:text-md text-gray-200 ">
          Embark on a rewarding development journey and turn your ideas into something extraordinary. Share your creations with a vibrant community, forge valuable connections, and unlock earning potential. Start developing with passion, purpose, and the opportunity to thrive in a supportive environment.
        </p> */}
        {/* <span className="block font-semibold text-gray-400">The best companion platform for your articles.</span> */}


        <div className="grid grid-cols-2 space-x-4 md:space-x-6 md:flex md:justify-center lg:justify-start mt-8">
          <Link aria-label="Become a dev" to="/menu" className="p-4 border bg-gray-800 bg-opacity-50 border-gray-700 rounded-full duration-300 hover:border-cyan-400 hover:shadow-lg hover:shadow-cyan-600/20 hover:border-cyan-300/30">
            <div className="flex justify-center md:space-x-4">
              {/* <b className="text-sky-500">&lt;/&gt;</b> */}
              {/* <img className="w-6 h-6 hidden md:block" src="https://cdn-icons-png.flaticon.com/512/458/458910.png" alt="slack logo" loading="lazy" width="128" height="128" /> */}
              <span className="font-medium block text-white">Burgers</span>
            </div>
          </Link>
          <Link aria-label="add to chat" to="/cart" className="p-4 border-2 bg-gray-800 bg-opacity-50 border-gray-700 rounded-full duration-300 hover:border-green-400 hover:shadow-lg hover:shadow-lime-600/20 hover:border-green-300/30">
            <div className="flex justify-center md:space-x-2">
              {/* <p className="hidden md:block">🛒</p> */}
              {/* <img className="w-6 h-6" src="https://cdn0.iconfinder.com/data/icons/leto-blue-project-management/64/_-19-256.png" alt="chat logo" loading="lazy" width="128" height="128" /> */}
              <span className="font-medium block text-white">Cart</span>
            </div>
          </Link>
          {/* <a aria-label="add to zoom" href="#" className="p-4 border border-gray-200 dark:bg-gray-800  dark:border-gray-700 rounded-full duration-300 hover:border-blue-400 hover:shadow-lg hover:shadow-blue-600/20 dark:hover:border-blue-300/30">
  <div className="flex justify-center space-x-4">
    <img className="w-6 h-6" src="https://tailus.io/sources/blocks/tech-startup/preview/images/zoom.png" alt="chat logo" loading="lazy" width="128" height="128" />
    <span className="hidden font-medium md:block dark:text-white">Zoom</span>
  </div>
</a> */}
        </div>

        {/* <div className="dark:text-gray-300">
🔥🌟
<span>Other integrations :</span>
<a href="#" className="font-semibold text-gray-700 dark:text-gray-200">Discord,</a>
<a href="#" className="font-semibold text-gray-700 dark:text-gray-200">Telegram</a>
</div> */}

        {/* <div className="pt-12 flex gap-6 lg:gap-12 justify-between grayscale lg:w-2/3">
<img src="https://tailus.io/sources/blocks/tech-startup/preview/images/clients/airbnb.svg" className="h-8 sm:h-10 w-auto lg:h-12" alt="" />
<img src="https://tailus.io/sources/blocks/tech-startup/preview/images/clients/ge.svg" className="h-8 sm:h-10 w-auto lg:h-12" alt="" />
<img src="https://tailus.io/sources/blocks/tech-startup/preview/images/clients/coty.svg" className="h-8 sm:h-10 w-auto lg:h-12" alt="" />
<img src="https://tailus.io/sources/blocks/tech-startup/preview/images/clients/transferwise.svg" className="h-8 sm:h-10 w-auto lg:h-12" alt="" />
</div> */}
      </div>

      <div>

      </div>
    </div>

    {/* 
    {!imageLoaded ? (
      // <div className="absolute inset-0 flex items-center justify-center bg-gray-200 animate-pulse">
<div></div>
      // </div>
    ) : (

    <img
      className={`w-full h-full rounded-2xl object-cover`}
      src={mainPoster}
      alt="Main Poster"
      onLoad={handleImageLoad}
    />

      )} */}




    <img class="hidden sm:block shadow-[0px_0px_15px_rgba(0,0,0,0.1)] w-full h-full rounded-2xl object-cover" src={mainPoster} loading="lazy" />
    <img class="block sm:hidden  shadow-[0px_0px_15px_rgba(0,0,0,0.1)] w-full h-full rounded-2xl object-cover" src={forSmall} loading="lazy" />





  </div>



  <div className="flex md:flex-row md:h-1/3 w-full">
    {/* <img class="bg-gray-200 mt-2 w-1/2 pr-2 rounded-2xl object-cover" src={downPoster1} loading="lazy" /> */}

    {/* <div class="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10"> */}
    <div class=" flex max-h-full w-full sm:rounded-2xl bg-white">

      <div className="flex flex-row w-full">






       {/* //left space */}

  


       <div className="h-[400px] w-full  hidden md:flex">
                  <div id="journal-scroll" className="flex-row overflow-y-auto whitespace-nowrap items-center justify-between flex space-x-20 lg:space-x-0">
                    <Link to='/transknit-attire/products'>
                      <ProductCard
                        category="Transknit"
                        name="Shirts"
                        price="BUY"
                        bgColor="bg-orange-500"
                        image="https://i.pinimg.com/originals/f7/1c/5c/f71c5c1e89dbb27a7e840b6fb60932eb.png"
                        priceTextColor="text-orange-500"
                      />
                    </Link>
                    <Link to='/transknit-attire/products'>
                      <ProductCard
                        category="Transknit"
                        name="Ethnic"
                        price="BUY"
                        bgColor="bg-teal-500"
                        image={Ethnic}
                        priceTextColor="text-teal-500"
                      />
                    </Link>
                    <Link to='/transknit-attire/products'>
                      <ProductCard
                        category="Transknit"
                        name="Bridal"
                        price="BUY"
                        bgColor="bg-purple-500"
                        image={Bridal}
                        priceTextColor="text-purple-500"
                      />
                    </Link>


                  </div>


                </div>
      

       {/* <div class="flex-col justify-center mt-20 font-montserrat hidden md:flex">
	<div
		class="relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 rounded-xl shadow-[0px_0px_15px_rgba(0,0,0,0.1)] p-3 max-w-xs md:max-w-4xl mx-auto border border-white bg-white">
		<div class="w-full md:w-1/3 bg-white grid place-items-center">
			<img src="https://images.pexels.com/photos/4381392/pexels-photo-4381392.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="tailwind logo" class="rounded-xl" />
    </div>
			<div class="w-full md:w-2/3 bg-white flex flex-col space-y-2 p-3">
				<div class="flex justify-between item-center">
					<p class="text-gray-500 font-medium hidden md:block">Vacations</p>
					<div class="flex items-center">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-yellow-500" viewBox="0 0 20 20"
							fill="currentColor">
							<path
								d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
						</svg>
						<p class="text-gray-600 font-bold text-sm ml-1">
							4.96
							<span class="text-gray-500 font-normal">(76 reviews)</span>
						</p>
					</div>
					<div class="">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-pink-500" viewBox="0 0 20 20"
							fill="currentColor">
							<path fill-rule="evenodd"
								d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
								clip-rule="evenodd" />
						</svg>
					</div>
					<div class="bg-gray-200 px-3 py-1 rounded-full text-xs font-medium text-gray-800 hidden md:block">
						Superhost</div>
				</div>
				<h3 class="font-black text-gray-600 md:text-xl text-md">The Majestic and Wonderful Bahamas</h3>
				<p class="md:text-md text-gray-500 text-sm">The best kept secret of The Bahamas is the country’s sheer
					size and diversity. With 16 major islands, The Bahamas is an unmatched destination</p>
				<p class="text-xl font-black text-gray-800">
					$110
					<span class="font-normal text-gray-600 text-base">/night</span>
				</p>
			</div>
		</div>
	</div> */}


        {/* <div class="w-1/2">
          <div class="m-8 my-20 max-w-[400px] mx-auto">
            <div class="mb-8">
              <h1 class="mb-4 text-3xl font-extrabold text-gray-900 font-montserrat">Significant Articles</h1>
              <p class="text-gray-600">Get the most out of GIGaDevDen by staying up to date with what's happening.</p>
            </div>
            <div class="space-y-4">
              <button onClick={() => window.location.href="/infodexeus/articles"} class="p-3 bg-black rounded-full text-white w-full font-semibold">Read Articles</button>
              <button onClick={() => window.location.href="console-home"} class="p-3 bg-white border rounded-full w-full font-semibold">Publish</button>
            </div>
          </div>
        </div> */}



        {/* <div class='w-1/2 flex items-center justify-center px-2'>
          <div class='w-full max-w-md  mx-auto bg-white rounded-3xl shadow-xl overflow-hidden'>
            <div class='max-w-md mx-auto'>
              <div class='h-[236px]' style={{
                backgroundImage: 'url(https://img.freepik.com/free-photo/pasta-spaghetti-with-shrimps-sauce_1220-5072.jpg?w=2000&t=st=1678041911~exp=1678042511~hmac=e4aa55e70f8c231d4d23832a611004f86eeb3b6ca067b3fa0c374ac78fe7aba6)',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
              >
              </div>
              <div class='p-4 sm:p-6'>
                <p class='font-bold text-gray-700 text-[22px] leading-7 mb-1'>GameDen Android Studio Base App</p>
                <div class='flex flex-row'>
                  <p class='text-[#3C3C4399] text-[17px] mr-2 line-through'>$ 154</p>
                  <p class='text-[17px] font-bold text-[#0FB478]'>$ 5</p>
                </div>
                <p class='text-[#7C7C80] font-[15px] mt-6'>Android stucio project for sale (Java): MySQL Database</p>


                <a target='_blank' href='' class='block mt-10 w-full px-4 py-3 font-medium tracking-wide text-center capitalize transition-colors duration-300 transform bg-[#FFC933] rounded-[14px] hover:bg-[#FFC933DD] focus:outline-none focus:ring focus:ring-teal-300 focus:ring-opacity-80'>
                  View Project
                </a>
                <a target='_blank' href="/" class='block mt-1.5 w-full px-4 py-3 font-medium tracking-wide text-center capitalize transition-colors duration-300 transform rounded-[14px] hover:bg-[#F2ECE7] hover:text-[#000000dd] focus:outline-none focus:ring focus:ring-teal-300 focus:ring-opacity-80'>
                  Download
                </a>
              </div>
            </div>
          </div>
        </div> */}




      </div>

    </div>
    {/* </div> */}

    {/* <div className="relative mt-2 w-1/2 pl-2 z-30">

      <div className='absolute px-4' >
      <div class="row bg-black bg-opacity-50 rounded-xl">
        <div class="col-xl-4 col-lg-5 col-sm-7 mx-lg-0 mx-auto">
          <div class="border-radius-xl p-1 mb-3 position-relative">
            <span class="mask bg-dark border-radius-xl z-index-1 opacity-5"></span>
            <div class="d-flex align-items-center z-index-2 position-relative">
              <a href="#soft-ui-dashboard-tailwind-builder" class="text-white icon-move-right text-sm ms-2 w-100 d-flex align-items-center">
                <div className="flex flex-row">
                  <span class="ml-2 rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white">new</span>
                  <span className="ml-2 mr-2 font-urbanist font-bold">GAMEDEN</span>
                </div>
                <i class="fas fa-chevron-right text-xs ms-auto me-2" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      </div>


      <img class="bg-gray-200 rounded-2xl object-cover" src="https://get.wallhere.com/photo/1920x1080-px-board-games-digital-art-logo-TV-657115.jpg" loading="lazy" />

    </div> */}


  </div>

</div>

<div className="hidden xl:w-1/3 xl:flex xl:flex-col h-full">
  {/* <img src={background} className='w-full p-16 rounded-lg opacity-30'/> */}

  <div class="rounded-2xl">
    <div class="group relative">
      {/* <img class="w-full object-cover block rounded-2xl" src={eventPoster} alt="" /> */}





      <div className="relative rounded-2xl overflow-hidden">
        <div
          className="flex transition-transform duration-1000 ease-in-out"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
          }}
        >
          {images.map((image, index) => (
            <div
              key={index}
              className={`p-2 w-full h-auto transition-transform duration-1000 ${index === currentIndex ? 'scale-100' : 'scale-50'
                }`}
              style={{
                flex: '0 0 100%',
              }}
            >
              <img
                src={image}
                loading="lazy"
                alt={`Carousel Slide ${index}`}
                className="shadow-[0px_0px_15px_rgba(0,0,0,0.1)] w-full h-auto object-cover rounded-2xl"

              //style={{ borderRadius: '1rem' }}

              />
              <div class="p-5">
                <div className="flex flex-row bg-gray-950 w-fit bg-opacity-25 px-1 py-1 rounded-full">
                  <span class="hidden rounded-full bg-sky-500 px-1 py-1 h-6 w-6 text-xs text-white sm:flex justify-center items-center">{imageDate[index]}</span>
                  <span className="mx-2">{imageText[index]}</span>
                </div>
                <p className="pt-2 text-sm">{imageDescription[index]}</p>
                {/* <h3 class="text-gray-950 text-xl font-urbanist font-bold">International Dog day <span className="text-gray-400 font-normal text-sm">26 Aug, 2023</span> </h3>
        <h3 class="text-white text-lg">Epoch <span className="text-gray-400">Tycho</span> </h3>
        <h3 class="text-white text-lg">Epoch <span className="text-gray-400">Tycho</span> </h3> */}

                {/* <p class="text-gray-400">Tycho</p> */}
              </div>
            </div>
          ))}
        </div>
      </div>


      {/* <div class="absolute bg-black rounded bg-opacity-0 group-hover:bg-opacity-60 w-full h-full top-0 flex items-center group-hover:opacity-100 transition justify-evenly rounded-t-2xl">
        <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
            <path d="M8 2.748l-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
          </svg>
        </button>

        <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-play-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
          </svg>
        </button>

        <button class="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
            <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
          </svg>
        </button>
      </div> */}
    </div>





  </div>


  {/* <img class="h-1/3 rounded-2xl object-cover" src="https://wallpaperaccess.com/full/3790191.jpg" />
  <img class="mt-2 h-1/3 rounded-2xl object-cover" src="https://wallpaperaccess.com/full/4422239.jpg" /> */}
  {/* <img class="mt-2 h-1/3 rounded-2xl object-cover" src="https://wallpaperaccess.com/full/4422576.jpg" /> */}

</div>

</div>








<style>{`
@import url('//fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap');
@import url(//pro.fontawesome.com/releases/v5.10.0/css/all.css);
@import url(//cdn.jsdelivr.net/npm/cryptocoins-icons@2.9.0/webfont/cryptocoins.css);
body {
font-family: Poppins, sans-serif;
}
.rounded-4xl {
border-radius: 3.5rem;
}
.phone-top {
position: absolute;
top: 0;
left: 50%;
transform: translateX(-50%);
width: 50%;
height: 3rem;
z-index: 1;
}
.phone-top-inner {
position: relative;
height: 2rem;
width: 100%;
background-color: #fff;
border-bottom-left-radius: 1.5rem;
border-bottom-right-radius: 1.5rem;
}
.phone-top-inner:before {
content: "";
position: absolute;
top: 0;
left: -1rem;
height: 1rem;
width: 1rem;
border-top-right-radius: 50%;
box-shadow: 0.5rem -0.5rem 0 0 #fff;
}
.phone-top-inner:after {
content: "";
position: absolute;
top: 0;
right: -1rem;
height: 1rem;
width: 1rem;
border-top-left-radius: 50%;
box-shadow: -0.5rem -0.5rem 0 0 #fff;
}
`}
</style>


          
            <style>
                {`
                body {
                    font-family : Urbanist, sans-serif;
                    @apply bg-gray-900
                }
        
                .navbar-active .hamburger div:first-child {
                    @apply rotate-45 translate-y-1.5;
                }
                .navbar-active .hamburger div:last-child {
                    @apply -rotate-45 -translate-y-1;
                }
                .navbar-active div:first-child div:first-child div:last-child{
                    @apply block lg:flex
                }
                `}
            </style>

        
    

{/* 
          <div class="absolute -top-40 lg:w-2/3 w-full h-80 -z-10">
        <svg xmlns='http://www.w3.org/2000/svg' class="drop-shadow-[-20px_10px_0px_#ffc949]" viewBox='0 0 2000 2000'
            fill='#ffb14a'>
            <path
                d='M994 112c-703-2-920.47 400.35-904 905 13.35 409 32.03 946.66 977 861 684-62 792-279 835-777 61.67-714.25-288.33-987.24-908-989Z'>
            </path>
        </svg>
    </div> */}





<div className="h-[380px] w-full px-5 flex md:hidden">
        <div id="journal-scroll" className="pl-[455px] flex flex-row overflow-y-auto  whitespace-nowrap items-center justify-center">
          <Link to='/transknit-attire/products'>
            <ProductCard
              category="Transknit"
              name="Shirts"
              price="BUY"
              bgColor="bg-orange-500"
              image="https://i.pinimg.com/originals/f7/1c/5c/f71c5c1e89dbb27a7e840b6fb60932eb.png"
              priceTextColor="text-orange-500"
            />
          </Link>
          <Link to='/transknit-attire/products'>
            <ProductCard
              category="Transknit"
              name="Ethnic"
              price="BUY"
              bgColor="bg-teal-500"
              image={Ethnic}
              priceTextColor="text-teal-500"
            />
          </Link>
          <Link to='/transknit-attire/products'>
            <ProductCard
              category="Transknit"
              name="Bridal"
              price="BUY"
              bgColor="bg-purple-500"
              image={Bridal}
              priceTextColor="text-purple-500"
            />
          </Link>
        </div>
      </div>




<style>
                    {`
  #journal-scroll {
    
    overflow-y: auto;
  }

  #journal-scroll::-webkit-scrollbar {
    height: 10px;
    width: 16px;
  }

  #journal-scroll::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 41px;
  }

  #journal-scroll:hover::-webkit-scrollbar-thumb {
    background: ; /* Change the scrollbar color to red on hover */
  }

  #journal-scroll::-webkit-scrollbar-track {
    background: transparent;
  }
`}
                </style>


<div class="2xl:container 2xl:mx-auto mx-2">
 

  <div class="lg:px-20 md:px-6 px-4 md:pb-12 md:pt-28 py-10">
    <div className="flex flex-row justify-start md:justify-between space-x-4 items-end">
      <h1 class="text-xl lg:text-2xl font-bold text-gray-800 dark:text-white text-center dark:text-gray-50">Best Sellers</h1>
      <h2 class="text-sm lg:text-md text-gray-800 dark:text-white text-center dark:text-gray-50 underline">View All</h2>
    </div>
    <div class="grid gap-6 lg:gap-8 mt-8 md:mt-10">





    <div id="journal-scroll" className="text-sm scrollable-container flex items-center space-x-4 overflow-y-auto whitespace-nowrap">


    <div class="bg-gray-50 dark:bg-gray-800 p-8">
        <div class="">
          <h2 class="text-xl text-gray-600 dark:text-white">Lounge Chair</h2>
          <p class="text-xl font-semibold text-gray-800 dark:text-white mt-2"></p>
        </div>
        <div class="flex justify-center items-center mt-8 md:mt-24">
          <img class="" src="https://i.ibb.co/8403ZFZ/pexels-hormel-2762247-removebg-preview-2-1.png" alt="A chair with designed back" role="img" />
        </div>
        <div class="flex justify-end items-center space-x-2 mt-16 md:mt-32">
          <button aria-label="show in red color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#DC2626" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in white color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="white" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in black color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#111827" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
        </div>
      </div>


      <div class="bg-gray-50 dark:bg-gray-800 p-8">
        <div class="">
          <h2 class="text-xl text-gray-600 dark:text-white">Lounge Chair</h2>
          <p class="text-xl font-semibold text-gray-800 dark:text-white mt-2"></p>
        </div>
        <div class="flex justify-center items-center mt-8 md:mt-24">
          <img class="" src="https://i.ibb.co/8403ZFZ/pexels-hormel-2762247-removebg-preview-2-1.png" alt="A chair with designed back" role="img" />
        </div>
        <div class="flex justify-end items-center space-x-2 mt-16 md:mt-32">
          <button aria-label="show in red color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#DC2626" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in white color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="white" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in black color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#111827" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
        </div>
      </div>


      <div class="bg-gray-50 dark:bg-gray-800 p-8">
        <div class="">
          <h2 class="text-xl text-gray-600 dark:text-white">Lounge Chair</h2>
          <p class="text-xl font-semibold text-gray-800 dark:text-white mt-2"></p>
        </div>
        <div class="flex justify-center items-center mt-8 md:mt-24">
          <img class="" src="https://i.ibb.co/8403ZFZ/pexels-hormel-2762247-removebg-preview-2-1.png" alt="A chair with designed back" role="img" />
        </div>
        <div class="flex justify-end items-center space-x-2 mt-16 md:mt-32">
          <button aria-label="show in red color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#DC2626" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in white color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="white" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in black color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#111827" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
        </div>
      </div>

      <div class="bg-gray-50 dark:bg-gray-800 p-8">
        <div class="">
          <h2 class="text-xl text-gray-600 dark:text-white">Lounge Chair</h2>
          <p class="text-xl font-semibold text-gray-800 dark:text-white mt-2"></p>
        </div>
        <div class="flex justify-center items-center mt-8 md:mt-24">
          <img class="" src="https://i.ibb.co/8403ZFZ/pexels-hormel-2762247-removebg-preview-2-1.png" alt="A chair with designed back" role="img" />
        </div>
        <div class="flex justify-end items-center space-x-2 mt-16 md:mt-32">
          <button aria-label="show in red color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#DC2626" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in white color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="white" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in black color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#111827" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
        </div>
      </div>

      <div class="bg-gray-50 dark:bg-gray-800 p-8">
        <div class="">
          <h2 class="text-xl text-gray-600 dark:text-white">Lounge Chair</h2>
          <p class="text-xl font-semibold text-gray-800 dark:text-white mt-2"></p>
        </div>
        <div class="flex justify-center items-center mt-8 md:mt-24">
          <img class="" src="https://i.ibb.co/8403ZFZ/pexels-hormel-2762247-removebg-preview-2-1.png" alt="A chair with designed back" role="img" />
        </div>
        <div class="flex justify-end items-center space-x-2 mt-16 md:mt-32">
          <button aria-label="show in red color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#DC2626" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in white color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="white" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in black color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#111827" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
        </div>
      </div>


      <div class="bg-gray-50 dark:bg-gray-800 p-8">
        <div class="">
          <h2 class="text-xl text-gray-600 dark:text-white">Lounge Chair</h2>
          <p class="text-xl font-semibold text-gray-800 dark:text-white mt-2"></p>
        </div>
        <div class="flex justify-center items-center mt-8 md:mt-24">
          <img class="" src="https://i.ibb.co/8403ZFZ/pexels-hormel-2762247-removebg-preview-2-1.png" alt="A chair with designed back" role="img" />
        </div>
        <div class="flex justify-end items-center space-x-2 mt-16 md:mt-32">
          <button aria-label="show in red color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#DC2626" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in white color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="white" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in black color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#111827" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
        </div>
      </div>


      


      
     

</div>


    </div>

    
  </div>
</div>





<div class="2xl:container 2xl:mx-auto mx-2">
 

  <div class="lg:px-20 md:px-6 px-4 md:pb-12 md:pt-28 py-10">
    <div className="flex flex-row justify-start md:justify-between space-x-4 items-end">
      <h1 class="text-xl lg:text-2xl font-bold text-gray-800 dark:text-white text-center dark:text-gray-50">New Arrivals</h1>
      <h2 class="text-sm lg:text-md text-gray-800 dark:text-white text-center dark:text-gray-50 underline">View All</h2>
    </div>
    <div class="grid gap-6 lg:gap-8 mt-8 md:mt-10">




    <div id="journal-scroll" className="text-sm scrollable-container flex items-center space-x-4 overflow-y-auto whitespace-nowrap">


    <div class="bg-gray-50 dark:bg-gray-800 p-8">
        <div class="">
          <h2 class="text-xl text-gray-600 dark:text-white">Lounge Chair</h2>
          <p class="text-xl font-semibold text-gray-800 dark:text-white mt-2"></p>
        </div>
        <div class="flex justify-center items-center mt-8 md:mt-24">
          <img class="" src="https://i.ibb.co/8403ZFZ/pexels-hormel-2762247-removebg-preview-2-1.png" alt="A chair with designed back" role="img" />
        </div>
        <div class="flex justify-end items-center space-x-2 mt-16 md:mt-32">
          <button aria-label="show in red color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#DC2626" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in white color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="white" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in black color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#111827" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
        </div>
      </div>


      <div class="bg-gray-50 dark:bg-gray-800 p-8">
        <div class="">
          <h2 class="text-xl text-gray-600 dark:text-white">Lounge Chair</h2>
          <p class="text-xl font-semibold text-gray-800 dark:text-white mt-2"></p>
        </div>
        <div class="flex justify-center items-center mt-8 md:mt-24">
          <img class="" src="https://i.ibb.co/8403ZFZ/pexels-hormel-2762247-removebg-preview-2-1.png" alt="A chair with designed back" role="img" />
        </div>
        <div class="flex justify-end items-center space-x-2 mt-16 md:mt-32">
          <button aria-label="show in red color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#DC2626" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in white color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="white" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in black color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#111827" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
        </div>
      </div>


      <div class="bg-gray-50 dark:bg-gray-800 p-8">
        <div class="">
          <h2 class="text-xl text-gray-600 dark:text-white">Lounge Chair</h2>
          <p class="text-xl font-semibold text-gray-800 dark:text-white mt-2"></p>
        </div>
        <div class="flex justify-center items-center mt-8 md:mt-24">
          <img class="" src="https://i.ibb.co/8403ZFZ/pexels-hormel-2762247-removebg-preview-2-1.png" alt="A chair with designed back" role="img" />
        </div>
        <div class="flex justify-end items-center space-x-2 mt-16 md:mt-32">
          <button aria-label="show in red color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#DC2626" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in white color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="white" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in black color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#111827" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
        </div>
      </div>


      <div class="bg-gray-50 dark:bg-gray-800 p-8">
        <div class="">
          <h2 class="text-xl text-gray-600 dark:text-white">Lounge Chair</h2>
          <p class="text-xl font-semibold text-gray-800 dark:text-white mt-2"></p>
        </div>
        <div class="flex justify-center items-center mt-8 md:mt-24">
          <img class="" src="https://i.ibb.co/8403ZFZ/pexels-hormel-2762247-removebg-preview-2-1.png" alt="A chair with designed back" role="img" />
        </div>
        <div class="flex justify-end items-center space-x-2 mt-16 md:mt-32">
          <button aria-label="show in red color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#DC2626" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in white color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="white" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in black color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#111827" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
        </div>
      </div>

      <div class="bg-gray-50 dark:bg-gray-800 p-8">
        <div class="">
          <h2 class="text-xl text-gray-600 dark:text-white">Lounge Chair</h2>
          <p class="text-xl font-semibold text-gray-800 dark:text-white mt-2"></p>
        </div>
        <div class="flex justify-center items-center mt-8 md:mt-24">
          <img class="" src="https://i.ibb.co/8403ZFZ/pexels-hormel-2762247-removebg-preview-2-1.png" alt="A chair with designed back" role="img" />
        </div>
        <div class="flex justify-end items-center space-x-2 mt-16 md:mt-32">
          <button aria-label="show in red color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#DC2626" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in white color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="white" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in black color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#111827" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
        </div>
      </div>

      <div class="bg-gray-50 dark:bg-gray-800 p-8">
        <div class="">
          <h2 class="text-xl text-gray-600 dark:text-white">Lounge Chair</h2>
          <p class="text-xl font-semibold text-gray-800 dark:text-white mt-2"></p>
        </div>
        <div class="flex justify-center items-center mt-8 md:mt-24">
          <img class="" src="https://i.ibb.co/8403ZFZ/pexels-hormel-2762247-removebg-preview-2-1.png" alt="A chair with designed back" role="img" />
        </div>
        <div class="flex justify-end items-center space-x-2 mt-16 md:mt-32">
          <button aria-label="show in red color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#DC2626" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in white color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="white" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
          <button aria-label="show in black color" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 rounded">
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="4.75" fill="#111827" stroke="#6B7280" stroke-width="0.5" />
            </svg>
          </button>
        </div>
      </div>

</div>


    </div>

    
  </div>
</div>





<div class="h-fit bg-white flex flex-col justify-center px-5 font-montserrat">
  

  <style>{`@import url(https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.min.css);`}</style>
<div class="min-w-screen min-h-screen bg-white flex items-center lg:p-10 overflow-hidden relative">
<div class="w-full rounded bg-white shadow-[0px_0px_15px_rgba(0,0,0,0.1)] text-gray-800 relative md:text-left m-1">
<div class="md:flex items-center -mx-10">
    <div class="w-full md:w-5/12 px-10 mb-10 md:mb-0">
        <div class="relative shadow-lg">
            <img src={food} class="relative z-10 rounded-md" alt=""/>
            <div class="border-4 border-yellow-200 absolute top-10 bottom-10 left-10 right-10 z-0"></div>
        </div>
    </div>
    
    <div class="w-full md:w-5/12 px-20 pb-10 md:pt-10">
        
        <div class="mb-10">
            <h1 class="font-bold uppercase text-2xl mb-5"><br/>Shirt & Trouser combo</h1>
            
            <p class="text-sm">1 Large Shirt & a Trouser.<a href="#" class="opacity-50 text-gray-900 hover:opacity-100 inline-block text-xs leading-none border-b border-gray-900"></a></p>
        </div>
        <div>
            <div class="inline-block align-bottom mr-5">
                <span class="text-2xl leading-none align-baseline">Rs </span>
                <span class="font-bold text-5xl leading-none align-baseline">2400</span>
                <span class="text-2xl leading-none align-baseline">.00</span>
            </div>
            <div class="inline-block align-bottom mt-4">
                <Link to="/menu" class="bg-yellow-300 opacity-75 hover:opacity-100 text-yellow-900 hover:text-gray-900 rounded-full px-10 py-2 font-semibold"><i class="mdi mdi-cart -ml-2 mr-2"></i> BUY NOW</Link>
            </div>
        </div>
    </div>

<div className=" w-2/12 hidden md:block">
<div class="bg-yellow-400 font-bold rounded-xl p-2 w-fit">4.8</div>

</div>

</div>
</div>
</div>





{/* <div class="pt-3 sm:mx-10">
<div class="bg-white shadow-lg max-h-80	 border sm:rounded-3xl p-8 flex space-x-8 items-center">
<div class="overflow-visible w-1/2">
  <img class="rounded-3xl" src={food} alt=""/>
</div>
<div class="flex flex-col w-1/2 space-y-4">
<div class="flex justify-between items-start">
  <h2 class="text-3xl font-bold">Deal: Pizza combo</h2>
  <div class="bg-yellow-400 font-bold rounded-xl p-2">4.9</div>
</div>
<div>
  <div class="text-sm text-gray-400">Deal</div>
  <div class="text-lg text-gray-800">2023</div>
</div>
  <p class=" text-gray-400 max-h-40 overflow-y-hidden">1 Large pizza (regular flavour), 4 zinger burgers and 1.5 Litre drink.</p>
<div class="flex text-2xl font-bold text-a">Rs 2400</div>
</div>

</div>
</div> */}

</div>






          <div class="bg-white flex items-center justify-center min-h-screen relative z-10 mb-16 font-montserrat">
   
       

    <div
        class="w-full max-w-[1190px] px-6 sm:px-8 md:px-16 py-10 md:py-20 rounded-xl bg-[#ffffff]/60 min-h-[300px] m-2 shadow-[0px_0px_15px_rgba(0,0,0,0.1)]">
        <h1 class="font-semibold text-2xl mb-2 tracking-wider drop-shadow-[3px_3px_5px_rgba(91,91,91,0.58)]">New Arrivals</h1>
        <small class="font-[500] text-lg">Taste the flavours designed for your taste buds</small>
        <ul class="flex items-start justify-between gap-8 mt-10 md:flex-row flex-col">
            <li
                class="w-full lg:w-[32%] px-10 pt-16 pb-8 bg-white rounded-lg self-stretch hover:scale-105 hover:shadow-[0px_14px_28px_-5px_rgba(0,0,0,0.1)] transition-all duration-150 ease-in">
                <h2
                    class="text-center font-semibold text-lg tracking-wider mb-3 drop-shadow-[3px_3px_5px_rgba(91,91,91,0.58)]">
                    Special Burger</h2>
                <p class="text-center tracking-tighter block mb-14">
                    <span class="text-4xl font-bold">380 Rs</span>
                    {/* <span class="text-black/40 text-center">/piece</span> */}
                </p>
                <Link to='/menu'>
                <button class="w-full p-2 bg-[#13181f] text-white rounded-md font-semibold hover:bg-[#ff2865] transition-all duration-150 ease-in mb-8 border-[#13181f]">add to cart</button>
                </Link>
                <ol class="list-disc text-[#ff2865] w-[80%] mx-auto">
                    <li><span class="text-black text-xs font-semibold">Opinion Rings</span></li>
                    <li><span class="text-black text-xs font-semibold">Cafe special sauce</span></li>
                    <li><span class="text-black text-xs font-semibold">Sizzler Chicken</span></li>
                </ol>
            </li>
            <li
                class="w-full lg:w-[32%] px-10 pt-16 pb-8 bg-white rounded-lg self-stretch hover:scale-105 hover:shadow-[0px_14px_28px_-5px_rgba(0,0,0,0.1)] transition-all duration-150 ease-in relative group">
                <div
                    class="absolute top-0 left-1/2 -translate-x-1/2 rounded-br-xl rounded-bl-xl text-white px-8 py-2 bg-[#ff2865] transition-all duration-150 ease-in group-hover:shadow-[0px_14px_28px_-5px_rgba(0,0,0,0.1)]">
                    Hot take!
                </div>
                <h2
                    class="text-center font-semibold text-lg tracking-wider mb-3 drop-shadow-[3px_3px_5px_rgba(91,91,91,0.58)]">
                    Pizza Burger</h2>
                <p class="text-center tracking-tighter block mb-14">
                    <span class="text-4xl font-bold">600 Rs</span>
                    {/* <span class="text-black/40 text-center">/piece</span> */}
                </p>
                <Link to='/menu'>
                <button class="w-full p-2 bg-[#13181f] text-white rounded-md font-semibold hover:bg-[#ff2865] transition-all duration-150 ease-in mb-8 border-[#13181f]">add to cart</button>
                </Link>
                <ol class="list-disc text-[#ff2865] w-[80%] mx-auto">
                    <li><span class="text-black text-xs font-semibold">Spicy Sauce</span></li>
                    <li><span class="text-black text-xs font-semibold">Homemade Bun</span></li>
                    <li><span class="text-black text-xs font-semibold">Pizza toppings</span></li>
                </ol>
            </li>
            <li
                class="w-full lg:w-[32%] px-10 pt-16 pb-8 bg-white rounded-lg self-stretch hover:scale-105 hover:shadow-[0px_14px_28px_-5px_rgba(0,0,0,0.1)] transition-all duration-150 ease-in relative group">
                <div
                    class="absolute top-0 left-1/2 -translate-x-1/2 rounded-br-xl rounded-bl-xl bg-[#e2e2e2] text-black px-8 py-2 group-hover:bg-red-800 group-hover:text-white transition-all duration-150 ease-in group-hover:shadow-[0px_14px_28px_-5px_rgba(0,0,0,0.1)]">
                    Sold Out
                </div>
                <h2
                    class="text-center font-semibold text-lg tracking-wider mb-3 drop-shadow-[3px_3px_5px_rgba(91,91,91,0.58)]">
                    Italian Pizza</h2>
                <p class="text-center tracking-tighter block mb-14">
                    <span class="text-4xl font-bold">2,600 Rs</span>
                    {/* <span class="text-black/40 text-center">/piece</span> */}
                </p>
                <button class="w-full p-2 bg-transparent text-[#13181f] rounded-md font-semibold hover:bg-[#13181f] hover:text-white transition-all duration-150 ease-in mb-8 border border-[#13181f]">Not Available</button>
                <ol class="list-disc text-[#ff2865] w-[80%] mx-auto">
                    <li><span class="text-black text-xs font-semibold">Traditional Baked</span></li>
                    <li><span class="text-black text-xs font-semibold">14 Inch</span></li>
                    <li><span class="text-black text-xs font-semibold">Mixed Meat</span></li>
                </ol>
            </li>
        </ul>
    </div>
</div>
    

         



<div className="flex items-center justify-center h-screen">
      <button
        className="bg-blue-500 text-white px-4 py-2 rounded-lg"
        onClick={() => setShowModal(true)}
      >
        Open Dialog
      </button>
      <DiagonalDialog showModal={showModal} setShowModal={setShowModal} />
    </div>


          
        


<Footer/>

    </div>
       
    );
}

export default Home;

