// import React from 'react';

// function ResponsiveCardList() {
//   const posts = [
//     {
//       image: "https://th.bing.com/th/id/R.8a8a106f11704375f3a65712141776be?rik=KEbePCROUoUJWQ&pid=ImgRaw&r=0",
//       title: "5 Easy Tips That Will Make Your Latte Art Flourish",
//       description: "Latte art is quite often the most attractive thing for a new barista, and latte art is an excellent gateway to the exciting world of coffee. Latte art easy to start with, but to master latte art patterns, you need a lot practice and determination. Here are my tips that helped me to improve my latte art a few years ago!",
//     },
//     {
//       image: "https://images.unsplash.com/photo-1512034400317-de97d7d6c3ed",
//       title: "Coffee Roasting Basics: Developing Flavour by Roasting",
//       description: "Caffé latte and flat white are definitely the most ordered espresso based drinks in cafés around the world but what are they really? Have you ever wondered the difference between caffé latte vs. flat white? Let's see what makes caffé latte and flat white different from each other!",
//     },
//     {
//       image: "https://images.unsplash.com/photo-1445077100181-a33e9ac94db0",
//       title: "Latte vs. Flat White - What is the Difference?",
//       description: "I bet roasting is the thing that every barista wants to know about! We can develop flavour by roasting coffee. How can we achieve the best tasting coffee? What actually happens when roasting?",
//     },
//     {
//       image: "https://images.unsplash.com/photo-1459257868276-5e65389e2722",
//       title: "Creating the Perfect Espresso Recipe",
//       description: "Espresso recipes are important in cafés in terms of consistency and flavour. How and why are the espresso recipes made and what are the things you should consider when making a recipe for espresso? Let’s dig deeper into the world of espresso!",
//     },
//   ];

//   // Shuffle the array
//   const shuffledPosts = posts
//     .map((post) => ({ sort: Math.random(), value: post }))
//     .sort((a, b) => a.sort - b.sort)
//     .map((entry) => entry.value);

//   return (
//     <section className="bg-gray-100 dark:bg-gray-900 py-10 px-12">
//       <div className="grid grid-flow-row gap-8 text-neutral-600 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
//         {shuffledPosts.map((post, index) => (
//           <div
//             key={index}
//             className="my-8 rounded shadow-lg shadow-gray-200 dark:shadow-gray-900 bg-white dark:bg-gray-800 duration-300 hover:-translate-y-1"
//           >
//             <a href="#" className="cursor-pointer">
//               <figure>
//                 <img
//                   src={post.image}
//                   className="rounded-t h-36 w-full object-cover"
//                   alt="Post"
//                 />
//                 <figcaption className="p-4">
//                   <p className="text-lg mb-4 font-bold leading-relaxed text-gray-800 dark:text-gray-300">
//                     {post.title}
//                   </p>
//                   <small className="leading-5 text-gray-500 dark:text-gray-400">
//                     {post.description}
//                   </small>
//                 </figcaption>
//               </figure>
//             </a>
//           </div>
//         ))}
//       </div>
//     </section>
//   );
// }

// export default ResponsiveCardList;



import React, { useState, useEffect } from 'react';
import { SiGoogledrive } from 'react-icons/si';
import { FaArrowRight, FaChessKing, FaCloudDownloadAlt, FaDownload, FaRegEye } from 'react-icons/fa';
import moment from 'moment';

import AliRubass from '../images/alirubass.jpeg';
import dotden from '../images/dotden.png'


import background from '../gif/food.gif'
import backgroundGrid from '../images/grid-hero.png'
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import food from '../images/4271316.png'
import food2 from '../images/42713162.png'


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { FaCheckDouble, FaWindowClose } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Pagination from '../partials/Pagination';



const EmployeeData = () => {



    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();

        fetchData();
    };



    // Total number of pages
    const [totalPages, setTotalPages] = useState(1);
    const [offset, setOffset] = useState(0);





    const [category, setCategory] = useState('');
    const [selectedOption, setSelectedOption] = useState('Top Sales');

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await fetch(
                `https://transknit.shop/database/get-public-products.php?search=${encodeURIComponent(searchTerm)}&category=${encodeURIComponent('Transknit Attire')}&page=${currentPage}&filter=${encodeURIComponent(selectedOption)}`
            );
            const json = await response.json();

            console.log(json);

            if (json.data.length === 0) {
                setTotalPages(0);
            } else {
                setTotalPages(Math.ceil(json.total_rows / 12));
                setData(json.data);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };


    const getFileIcon = (driveLink) => {
        const fileExtension = driveLink.split('.').pop().toLowerCase();

        if (fileExtension === 'pdf') {
            return <SiGoogledrive className="w-6 h-6 text-red-500" />;
        } else if (fileExtension === 'doc' || fileExtension === 'docx') {
            return <SiGoogledrive className="w-6 h-6 text-blue-500" />;
        } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
            return <SiGoogledrive className="w-6 h-6 text-green-500" />;
        } else {
            return <SiGoogledrive className="w-6 h-6 text-gray-500" />;
        }
    };

    const downloadFile = (driveLink) => {
        window.open(driveLink, '_blank');
    };

    const [currentPage, setCurrentPage] = useState(1);


    const handlePageChange = (page) => {

        if (page >= 1 && page <= totalPages) {
            setOffset((page - 1) * 12);
            setCurrentPage(page);
        }

        // Perform any other actions or fetch data based on the new page
    };

    const websitePrefix = 'www.transknit.shop';

    function setCurrentProjectId(id, title) {
        const hash = window.location.hash;
        const formattedTitle = title
            .toLowerCase() // Convert title to lowercase
            .replace(/[^a-zA-Z0-9\s]/g, ' ') // Remove special characters
            .trim()
            .replace(/\s+/g, '-'); // Replace spaces with dashes
        window.location.href = hash + '/' + id + '/' + formattedTitle;
    }





    // Filters
    const [showFilters, setShowfilters] = useState(false);

    const [check, setCheck] = useState({
        "All": true,
        "Deals": false,
        "Pizzas": false,
        "Burgers": false,
        "Pastas": false,
        "Sides": false,
        "Specials": false,
    });

    const { "All": All, "Deals": Deals, "Pizzas": Pizzas, "Burgers": Burgers, "Pastas": Pastas, "Sides": Sides, "Specials": Specials } = check;








    const [selectedButton, setSelectedButton] = useState("Burgers");


    const changeHandler = (e) => {
        const { name } = e.target;
        setSelectedButton(e.target.name);
        setCategory(name);

    };



    const applyFilters = (e) => {
        setCheck({
            ...check,
            "All": All,
            "Deals": false,
            "Pizzas": false,
            "Burgers": false,
            "Pastas": false,
            "Sides": false,
            "Specials": false,
        });
    };



    const [check2, setCheck2] = useState({
        "": true,
        "Upload date: New to Old": false,
        "Upload date: Old to New": false,
    });

    const { "": Relevance, "Upload date: New to Old": New, "Upload date: Old to New": Old } = check2;

    const [sortby, setSortby] = useState('');

    const changeHandler2 = (e) => {
        const { name, checked } = e.target;

        // If the checkbox is being checked, set its value to true and set all other checkboxes to false
        if (checked) {
            setCheck2((prevState) => ({
                ...Object.fromEntries(Object.keys(prevState).map((key) => [key, false])),

                [name]: true,
            }));

            setSortby(name);
        }
        // else {
        //   // If the checkbox is being unchecked, set its value to false
        //   setCheck3((prevState) => ({
        //     ...prevState,
        //     [name]: false,
        //   }));

        //   setPrice('');
        // }
    };


    const applyFilters2 = (e) => {
        setCheck({
            ...check2,
            "": true,
            "Upload date: New to Old": false,
            "Upload date: Old to New": false,
        });
    };



    const [check3, setCheck3] = useState({
        "": true,
        "Price: Low to High": false,
        "Price: High to Low": false,
        "Free": false,
    });

    const { "": All3, "Price: Low to High": Low, "Price: High to Low": High, "Free": Free } = check3;

    const [price, setPrice] = useState('');

    const changeHandler3 = (e) => {
        const { name, checked } = e.target;

        // If the checkbox is being checked, set its value to true and set all other checkboxes to false
        if (checked) {
            setCheck3((prevState) => ({
                ...Object.fromEntries(Object.keys(prevState).map((key) => [key, false])),

                [name]: true,
            }));

            setPrice(name);
        }
        // else {
        //   // If the checkbox is being unchecked, set its value to false
        //   setCheck3((prevState) => ({
        //     ...prevState,
        //     [name]: false,
        //   }));

        //   setPrice('');
        // }
    };


    const applyFilters3 = (e) => {
        setCheck({
            ...check3,
            "": false,
            "Price: Low to High": false,
            "Price: High to Low": false,
            "Free": false,
        });
    };





    const apply = (e) => {
        fetchData();
    };



    // const getTimeDifference = (timestamp, time) => {
    //   const currentTime = moment(timestamp);
    //   const oldTime = moment(time);
    //   const duration = moment.duration(currentTime.diff(oldTime));

    //   if (duration.asMinutes() < 60) {
    //     return `${Math.round(duration.asMinutes())} min ago`;
    //   } else if (duration.asHours() < 24) {
    //     return `${Math.round(duration.asHours())} hour ago`;
    //   } else if (duration.asDays() < 7) {
    //     return `${Math.round(duration.asDays())}d ago`;
    //   } else if (duration.asMonths() < 1) {
    //     return `${Math.round(duration.asWeeks())}w ago`;
    //   } else if (duration.asYears() < 1) {
    //     return `${Math.round(duration.asMonths())} month ago`;
    //   } else {
    //     return `${Math.round(duration.asYears())} year ago`;
    //   }
    // };

    const getTimeDifference = (timestamp, time) => {
        const currentTime = moment.utc(timestamp);
        const oldTime = moment.utc(time, "YYYY-MM-DD HH:mm:ss");
        const duration = moment.duration(currentTime.diff(oldTime));

        if (duration.asMinutes() < 60) {
            return `${Math.round(duration.asMinutes())} min ago`;
        } else if (duration.asHours() < 24) {
            return `${Math.round(duration.asHours())} hour ago`;
        } else if (duration.asDays() < 7) {
            return `${Math.round(duration.asDays())}d ago`;
        } else if (duration.asMonths() < 1) {
            return `${Math.round(duration.asWeeks())}w ago`;
        } else if (duration.asYears() < 1) {
            return `${Math.round(duration.asMonths())} month ago`;
        } else {
            return `${Math.round(duration.asYears())} year ago`;
        }
    };


    useEffect(() => {

        window.scrollTo(0, 0); // Scroll to top when component mounts

        // if (localStorage.getItem(`${websitePrefix}-current`) === '0') {
        //     setCategory('Transknit Attire');
        //    } else if (localStorage.getItem(`${websitePrefix}-current`) === '1') {
        //      setCategory('Transknit Home');
        //    }
        fetchData();
    }, [category, searchTerm]);



    useEffect(() => {


        // if (localStorage.getItem(`${websitePrefix}-current`) === '0') {
        //     setCategory('Transknit Attire');
        //    } else if (localStorage.getItem(`${websitePrefix}-current`) === '1') {
        //      setCategory('Transknit Home');
        //    }
        fetchData();
    }, [selectedOption]);


    const updateCart = async (id) => {

        if (localStorage.getItem(`${websitePrefix}-publiclogin`) === '1') {

            const userid = localStorage.getItem(`${websitePrefix}-publicid`);

            try {
                const formData = new FormData();
                formData.append('userid', userid);
                formData.append('productid', id);
                console.log("text " + id + " 2nd " + userid);

                const response = await fetch('https://transknit.shop/database/add-to-cart.php', {
                    method: 'POST',
                    body: formData,
                });

                const json = await response.json();

                if (json.success === true) {
                    // Added successfully

                    toast(
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FaCheckDouble className="mr-2 text-green-600" />
                            <span>Added to Cart</span>
                        </div>,
                        {
                            className: 'custom-toast-success', // Add a custom CSS class for styling
                        }
                    );


                } else {
                    // Failed to add

                    toast(
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <FaWindowClose className="mr-2 text-red-600" />
                            <span>could not Add</span>
                        </div>,
                        {
                            className: 'custom-toast-success', // Add a custom CSS class for styling
                        }
                    );

                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }

        }
        else {
            toast(
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FaWindowClose className="mr-2 text-red-600" />
                    <span>Please login first</span>
                </div>,
                {
                    className: 'custom-toast-success', // Add a custom CSS class for styling
                }
            );
        }


    };



    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };



    function AddtoCart(id) {

        updateCart(id);
    }


    const [isOpen, setIsOpen] = useState(false);

    const options = [
        'Top Sales',
        'Newest Arrivals',
        'Price Low to High',
        'Price High to Low',
    ];

    const handleChange = (option) => {
        setSelectedOption(option);

        setIsOpen(false);
    };



    return (
        // <div className="bg-black font-inter antialiased bg-black text-gray-200 tracking-tight">
        <div className="bg-white font-urbanist relative"
        // style={{backgroundImage: "url('https://img.freepik.com/free-photo/delicious-burger-with-many-ingredients-isolated-white-background-tasty-cheeseburger-splash-sauce_90220-1266.jpg?w=740&t=st=1699643932~exp=1699644532~hmac=880112d349defb66bdb632989a5797f9dd3ee29b9aee328a28ff1286ed9c250c')",
        // backgroundSize: 'auto 50%',
        // backgroundRepeat: 'no-repeat'}}
        >




            <Header />




            <div className="bg-white flex flex-col w-full items-center justify-center opacity-95 md:hidden">




                <div id="filterSection" className={"relative md:py-5 lg:px-20 md:px-6 py-4 px-4 bg-gray-50 rounded w-full " + (showFilters ? "block" : "hidden")}>
                    {/* Cross button Code  */}
                    <div onClick={() => setShowfilters(false)} className=" cursor-pointer absolute right-0 top-0 md:py-10 lg:px-20 md:px-6 py-9 px-4">
                        <svg className=" lg:w-6 lg:h-6 w-4 h-4" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 1L1 25" stroke="#1F2937" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1 1L25 25" stroke="#27272A" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>







                    <hr className="bg-gray-200 lg:w-6/12 w-full" />


                    <div>
                        <div className=" flex space-x-2">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 5H14" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 7L14 5L12 3" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M5 3L3 5L5 7" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M19 10V21" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17 19L19 21L21 19" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M21 12L19 10L17 12" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 10H5C3.89543 10 3 10.8954 3 12V19C3 20.1046 3.89543 21 5 21H12C13.1046 21 14 20.1046 14 19V12C14 10.8954 13.1046 10 12 10Z" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <p className="  lg:text-2xl text-xl lg:leading-6 leading-5 font-medium text-gray-800 ">Order by</p>
                        </div>
                        <div className=" md:flex md:space-x-6 mt-8 grid grid-cols-3 gap-y-8 flex-wrap">
                            <div className=" flex md:justify-center md:items-center items-center justify-start ">
                                <input className="w-4 h-4 mr-2" type="checkbox" id="" name="" value="" checked={Relevance} onChange={changeHandler2} />
                                <div className=" inline-block">
                                    <div className=" flex space-x-6 justify-center items-center">
                                        <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="Relevance">
                                            Relevance
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className=" flex justify-center items-center ">
                                <input className="w-4 h-4 mr-2" type="checkbox" id="Upload date: New to Old" name="Upload date: New to Old" value="Upload date: New to Old" checked={New} onChange={changeHandler2} />
                                <div className=" inline-block">
                                    <div className=" flex space-x-6 justify-center items-center">
                                        <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="Upload date: New to Old">
                                            New to Old
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className=" flex md:justify-center md:items-center items-center justify-end ">
                                <input className="w-4 h-4 mr-2" type="checkbox" id="Upload date: Old to New" name="Upload date: Old to New" value="Upload date: Old to New" checked={Old} onChange={changeHandler2} />
                                <div className=" inline-block">
                                    <div className=" flex space-x-6 justify-center items-center">
                                        <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="Upload date: Old to New">
                                            Old to New
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className=" bg-gray-200 lg:w-6/12 w-full md:my-10 my-8" />



                    {/* Collection Section */}

                    <div>
                        <div className=" flex space-x-2">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g opacity="0.8">
                                    <path d="M9 4H5C4.44772 4 4 4.44772 4 5V9C4 9.55228 4.44772 10 5 10H9C9.55228 10 10 9.55228 10 9V5C10 4.44772 9.55228 4 9 4Z" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9 14H5C4.44772 14 4 14.4477 4 15V19C4 19.5523 4.44772 20 5 20H9C9.55228 20 10 19.5523 10 19V15C10 14.4477 9.55228 14 9 14Z" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M19 14H15C14.4477 14 14 14.4477 14 15V19C14 19.5523 14.4477 20 15 20H19C19.5523 20 20 19.5523 20 19V15C20 14.4477 19.5523 14 19 14Z" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M14 7H20" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17 4V10" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                            </svg>
                            <p className=" lg:text-2xl text-xl lg:leading-6 leading-5 font-medium text-gray-800 ">Collection</p>
                        </div>
                        <div className=" flex mt-8 space-x-7">
                            <div className=" flex justify-center items-center">
                                <input className="w-4 h-4 mr-2" type="checkbox" id="" name="" checked={All3} onChange={changeHandler3} value="" />
                                <div className=" inline-block">
                                    <div className=" flex space-x-6 justify-center items-center">
                                        <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="All">
                                            All
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className=" flex justify-center items-center">
                                <input className="w-4 h-4 mr-2" type="checkbox" id="Free" name="Free" value="Free" checked={Free} onChange={changeHandler3} />
                                <div className=" inline-block">
                                    <div className=" flex space-x-6 justify-center items-center">
                                        <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="Free">
                                            Free
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className=" flex justify-center items-center">
                                <input className="w-4 h-4 mr-2" type="checkbox" id="Price: Low to High" name="Price: Low to High" checked={Low} onChange={changeHandler3} value="Price: Low to High" />
                                <div className=" inline-block">
                                    <div className=" flex space-x-6 justify-center items-center">
                                        <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="Price: Low to High">
                                            Price ↑
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className=" flex justify-center items-center">
                                <input className="w-4 h-4 mr-2" type="checkbox" id="Price: High to Low" name="Price: High to Low" checked={High} onChange={changeHandler3} value="Price: High to Low" />
                                <div className=" inline-block">
                                    <div className=" flex space-x-6 justify-center items-center">
                                        <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="Price: High to Low">
                                            Price ↓
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="px-0 mt-10 w-full md:w-auto md:mt-0 md:absolute md:right-0 md:bottom-0 md:py-10 lg:px-20 md:px-6">
                        <button onClick={() => { setShowfilters(!showFilters); apply(); }} className="w-full hover:bg-gray-700 focus:ring focus:ring-offset-2 focus:ring-gray-800 text-base leading-4 font-medium py-4 px-10 text-white bg-gray-800 rounded">

                            Apply Filter
                        </button>
                    </div>
                </div>





            </div>


            <div className="relative w-full opacity-20 rounded pointer-events-none z-10 -mt-16 md:-mt-0">
                <div className="relative">
                    {/* <video className="w-full" controls autoPlay muted loop>
                        <source src={background} type="video/mp4" />
                    </video> */}
                    <img src="https://th.bing.com/th/id/R.47e00e05f8d6528583202cd96e18992a?rik=h%2fdgUexj7W7KZg&riu=http%3a%2f%2fimages4.fanpop.com%2fimage%2fphotos%2f20400000%2fPIZZA-pizza-20461160-1600-1200.jpg&ehk=Qw%2bjfQA1O8UbLkJhLixjt0h93BHTRzHfXScMqKg1h70%3d&risl=&pid=ImgRaw&r=0" className="w-full opacity-0" />
                    <div className="absolute inset-0 bg-gradient-to-b from-gray-950 via-transparent to-transparent opacity-0"></div>

                    <div className="absolute inset-0 bg-gradient-to-t from-gray-950 via-transparent to-transparent opacity-0"></div>

                </div>
            </div>

            {/* <video src={background} className='absolute right-48 w-[600px] opacity-10 rounded pointer-events-none border animate-'/> */}
            <img src={backgroundGrid} className='absolute w-full md:px-16 lg:px-0 lg:right-0 lg:w-[800px] opacity-20 pointer-events-none -mt-[55%] md:-mt-[50%]' />
            <div className="relative container mx-auto min-h-screen px-4 2xl:px-24 -mt-[64%] md:-mt-[72%] z-20">





                {/* Filters */}

                <div className="">
                    <div className=" md:py-0 py-4 px-4">







                        {/* <p className=" text-sm leading-3 text-gray-600 font-normal mb-4 md:mb-2">Home - Content - Articles</p> */}

                        {/* <div class="hidden md:flex space-x-4 text-gray-400 mb-8 md:mb-4">
                            <span>Home</span>
                            <svg aria-hidden="true" width="24" height="24" fill="none" class="flex-none text-gray-400">
                                <path d="M10.75 8.75l3.5 3.25-3.5 3.25" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                            <span class="block w-max text-white border-b-2 border-cyan-400">Menu</span>
                        </div> */}


                        <div className=" flex  items-center ">
                            {/* <h2 className=" lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-200 font-semibold hidden md:block">Articles</h2> */}
                            <h1 className="text-gray-600 text-3xl md:text-5xl font-bold leading-none rounded-full hidden md:block border-t-2 border-gray-700">Transknit <span>Attire</span></h1>

                            <div class="relative mt-auto w-screen">

                                <Link to="/cart" class="flex flex-col py-12 lg:py-36">
                                    <img src={food} alt="" class="hidden xl:block absolute bottom-0 left-0 object-cover w-full h-full overflow-hidden rounded-md opacity-100" />
                                    <img src={food2} alt="" class="block xl:hidden absolute bottom-0 left-0 object-cover w-full h-full overflow-hidden rounded-md opacity-100" />

                                    <div class="z-10 flex-col items-start justify-center w-2/3 px-6 xl:px-8">
                                        {/* <img src="/images/ap/tailwind-logo.png" alt="tailwind-logo" class="h-8"/> */}
                                        <p class="mt-3 text-xs md:text-sm tracking-wide text-gray-900">Elegance Redefined</p>
                                        <p class="mb-1 text-base font-bold tracking-wide text-gray-900 md:text-lg">Discover Timeless Fashion</p>
                                        <button class="px-5 py-2 mt-2 text-xs font-bold text-white rounded-lg shadow-md bg-gradient-to-tl from-red-700 to-pink-500 uppercase">
                                            Cart
                                        </button></div></Link>



                            </div>



                            {/*  filters Button (md and plus Screen) */}
                            {/* <button onClick={() => setShowfilters(!showFilters)} className=" cursor-pointer sm:flex hidden hover:bg-gray-700 focus:ring focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-4 px-6 bg-gray-800 rounded-full flex text-base leading-4 font-normal text-white justify-center items-center ">
                                <svg className=" mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 12C7.10457 12 8 11.1046 8 10C8 8.89543 7.10457 8 6 8C4.89543 8 4 8.89543 4 10C4 11.1046 4.89543 12 6 12Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6 4V8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6 12V20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 18C13.1046 18 14 17.1046 14 16C14 14.8954 13.1046 14 12 14C10.8954 14 10 14.8954 10 16C10 17.1046 10.8954 18 12 18Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 4V14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 18V20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18 9C19.1046 9 20 8.10457 20 7C20 5.89543 19.1046 5 18 5C16.8954 5 16 5.89543 16 7C16 8.10457 16.8954 9 18 9Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18 4V5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M18 9V20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                Filters
                            </button> */}
                        </div>


                        <div className="bg-white flex-col md:flex-row w-full items-center justify-center opacity-95 md:hidden z-50 relative space-x-4 mt-10">

                            <nav className="flex z-50">
                                {/* other navbar content */}
                                <form onSubmit={handleSearchSubmit} className="flex items-center">
                                    {/* <SearchIcon className="h-4 w-4 text-gray-500" /> */}
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        className="pl-3 pr-10 py-1 rounded-full border border-gray-950 bg-white opacity-50 text-gray-800"
                                    />
                                    {/* <button onClick={() => setShowfilters(!showFilters)} className="-ml-20 z-10 cursor-pointer block sm:hidden py-2 leading-4 rounded text-xs text-white justify-center items-center">
<svg className=" mr-2" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 12C7.10457 12 8 11.1046 8 10C8 8.89543 7.10457 8 6 8C4.89543 8 4 8.89543 4 10C4 11.1046 4.89543 12 6 12Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 4V8" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6 12V20" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 18C13.1046 18 14 17.1046 14 16C14 14.8954 13.1046 14 12 14C10.8954 14 10 14.8954 10 16C10 17.1046 10.8954 18 12 18Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 4V14" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 18V20" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18 9C19.1046 9 20 8.10457 20 7C20 5.89543 19.1046 5 18 5C16.8954 5 16 5.89543 16 7C16 8.10457 16.8954 9 18 9Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18 4V5" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18 9V20" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
</svg>

</button> */}
                                </form>

                            </nav>


                            <div className="relative inline-block text-left mt-4 md:mt-0">
                                <button
                                    type="button"
                                    onClick={() => setIsOpen(!isOpen)}
                                    className="inline-flex justify-between w-44 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                                >
                                    {selectedOption}
                                    <svg
                                        className={`w-5 h-5 transition-transform duration-200 transform ${isOpen ? 'rotate-180' : 'rotate-0'
                                            }`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>

                                {isOpen && (
                                    <div className="absolute right-0 z-10 w-48 mt-2 origin-top-right bg-white border border-gray-300 rounded-md shadow-lg">
                                        <div className="py-1">
                                            {options.map((option, index) => (
                                                <button
                                                    key={index}
                                                    onClick={() => handleChange(option)}
                                                    className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                                                >
                                                    {option}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                        <p className="hidden lg:block text-sm mt-2 md:mt-0 md:text-md leading-5 text-gray-400 font-medium pb-10">
                            {data[0]?.totalrows ? (
                                data[0].totalrows < 10 ? '0' + data[0].totalrows.toString() : data[0].totalrows.toString()
                            ) : null}
                            {/* Result<b className="text-sm">(s)</b> */}
                        </p>


                    </div>

                    <div id="filterSection" className={"relative md:py-5 lg:px-20 md:px-6 py-4 px-4 bg-gray-50 rounded w-full " + (showFilters ? "hidden md:block" : "hidden")}>
                        {/* Cross button Code  */}
                        <div onClick={() => setShowfilters(false)} className=" cursor-pointer absolute right-0 top-0 md:py-10 lg:px-20 md:px-6 py-9 px-4">
                            <svg className=" lg:w-6 lg:h-6 w-4 h-4" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25 1L1 25" stroke="#1F2937" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M1 1L25 25" stroke="#27272A" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>







                        <hr className="bg-gray-200 lg:w-6/12 w-full" />


                        <div>
                            <div className=" flex space-x-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 5H14" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 7L14 5L12 3" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5 3L3 5L5 7" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M19 10V21" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17 19L19 21L21 19" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M21 12L19 10L17 12" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M12 10H5C3.89543 10 3 10.8954 3 12V19C3 20.1046 3.89543 21 5 21H12C13.1046 21 14 20.1046 14 19V12C14 10.8954 13.1046 10 12 10Z" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <p className="  lg:text-2xl text-xl lg:leading-6 leading-5 font-medium text-gray-800 ">Order by</p>
                            </div>
                            <div className=" md:flex md:space-x-6 mt-8 grid grid-cols-3 gap-y-8 flex-wrap">
                                <div className=" flex md:justify-center md:items-center items-center justify-start ">
                                    <input className="w-4 h-4 mr-2" type="checkbox" id="" name="" value="" checked={Relevance} onChange={changeHandler2} />
                                    <div className=" inline-block">
                                        <div className=" flex space-x-6 justify-center items-center">
                                            <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="Relevance">
                                                Relevance
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className=" flex justify-center items-center ">
                                    <input className="w-4 h-4 mr-2" type="checkbox" id="Upload date: New to Old" name="Upload date: New to Old" value="Upload date: New to Old" checked={New} onChange={changeHandler2} />
                                    <div className=" inline-block">
                                        <div className=" flex space-x-6 justify-center items-center">
                                            <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="Upload date: New to Old">
                                                New to Old
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className=" flex md:justify-center md:items-center items-center justify-end ">
                                    <input className="w-4 h-4 mr-2" type="checkbox" id="Upload date: Old to New" name="Upload date: Old to New" value="Upload date: Old to New" checked={Old} onChange={changeHandler2} />
                                    <div className=" inline-block">
                                        <div className=" flex space-x-6 justify-center items-center">
                                            <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="Upload date: Old to New">
                                                Old to New
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className=" bg-gray-200 lg:w-6/12 w-full md:my-10 my-8" />



                        {/* Collection Section */}

                        <div>
                            <div className=" flex space-x-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.8">
                                        <path d="M9 4H5C4.44772 4 4 4.44772 4 5V9C4 9.55228 4.44772 10 5 10H9C9.55228 10 10 9.55228 10 9V5C10 4.44772 9.55228 4 9 4Z" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9 14H5C4.44772 14 4 14.4477 4 15V19C4 19.5523 4.44772 20 5 20H9C9.55228 20 10 19.5523 10 19V15C10 14.4477 9.55228 14 9 14Z" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M19 14H15C14.4477 14 14 14.4477 14 15V19C14 19.5523 14.4477 20 15 20H19C19.5523 20 20 19.5523 20 19V15C20 14.4477 19.5523 14 19 14Z" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M14 7H20" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M17 4V10" stroke="#1F2937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                </svg>
                                <p className=" lg:text-2xl text-xl lg:leading-6 leading-5 font-medium text-gray-800 ">Collection</p>
                            </div>
                            <div className=" flex mt-8 space-x-7">
                                <div className=" flex justify-center items-center">
                                    <input className="w-4 h-4 mr-2" type="checkbox" id="" name="" checked={All3} onChange={changeHandler3} value="" />
                                    <div className=" inline-block">
                                        <div className=" flex space-x-6 justify-center items-center">
                                            <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="All">
                                                All
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className=" flex justify-center items-center">
                                    <input className="w-4 h-4 mr-2" type="checkbox" id="Free" name="Free" value="Free" checked={Free} onChange={changeHandler3} />
                                    <div className=" inline-block">
                                        <div className=" flex space-x-6 justify-center items-center">
                                            <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="Free">
                                                Free
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className=" flex justify-center items-center">
                                    <input className="w-4 h-4 mr-2" type="checkbox" id="Price: Low to High" name="Price: Low to High" checked={Low} onChange={changeHandler3} value="Price: Low to High" />
                                    <div className=" inline-block">
                                        <div className=" flex space-x-6 justify-center items-center">
                                            <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="Price: Low to High">
                                                Price ↑
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className=" flex justify-center items-center">
                                    <input className="w-4 h-4 mr-2" type="checkbox" id="Price: High to Low" name="Price: High to Low" checked={High} onChange={changeHandler3} value="Price: High to Low" />
                                    <div className=" inline-block">
                                        <div className=" flex space-x-6 justify-center items-center">
                                            <label className=" mr-2 text-sm leading-3 font-normal text-gray-600" htmlFor="Price: High to Low">
                                                Price ↓
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="px-0 mt-10 w-full md:w-auto md:mt-0 md:absolute md:right-0 md:bottom-0 md:py-10 lg:px-20 md:px-6">
                            <button onClick={() => { setShowfilters(!showFilters); apply(); }} className="w-full hover:bg-gray-700 focus:ring focus:ring-offset-2 focus:ring-gray-800 text-base leading-4 font-medium py-4 px-10 text-white bg-gray-800 rounded">

                                Apply Filter
                            </button>
                        </div>
                    </div>
                </div>


                <div class="md:flex md:items-center md:justify-center md:space-x-8 mb-16 md:mb-20">


                    {/* filter scroll here */}

                    <nav className=" hidden md:flex">
                        {/* other navbar content */}
                        <form onSubmit={handleSearchSubmit} className="flex items-center">
                            {/* <SearchIcon className="h-4 w-4 text-gray-500" /> */}
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                className="ml-2 pl-3 pr-14 py-1 rounded-full border border-gray-300"
                            />
                        </form>
                    </nav>

                    <div className="relative md:inline-block text-left hidden">
                        <button
                            type="button"
                            onClick={() => setIsOpen(!isOpen)}
                            className="inline-flex justify-between w-48 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
                        >
                            {selectedOption}
                            <svg
                                className={`w-5 h-5 transition-transform duration-200 transform ${isOpen ? 'rotate-180' : 'rotate-0'
                                    }`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>

                        {isOpen && (
                            <div className="absolute right-0 z-10 w-48 mt-2 origin-top-right bg-white border border-gray-300 rounded-md shadow-lg">
                                <div className="py-1">
                                    {options.map((option, index) => (
                                        <button
                                            key={index}
                                            onClick={() => handleChange(option)}
                                            className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                                        >
                                            {option}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>


                    {/* 
                    <div className="relative inline-block w-auto mb-16 mt-8">
                        <div>
                            <button
                                type="button"
                                className="flex items-center justify-center px-4 py-2 mt-4 space-x-3 text-gray-600 transition-colors duration-300 transform border rounded-lg md:mt-0 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-primary dark:focus:border-primary focus:outline-none focus:ring focus:ring-primary focus:ring-opacity-20"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="w-4 h-4"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                                <span>All version</span>
                            </button>
                        </div>
                        <div className="absolute left-0 z-20 py-1 mt-2 bg-yellow-500 border border-gray-100 rounded-md shadow-xl xl:left-auto xl:right-0" style={{ display: "none" }}>
                            <div className="w-32">
                                <a href="https://tailwindcomponents.com/components/grid" className="block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-300 transform hover:text-primary">
                                    All version
                                </a>
                                <a href="https://tailwindcomponents.com/components/grid?version=3" className="block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-300 transform hover:text-primary">
                                    version 3
                                </a>
                                <a href="https://tailwindcomponents.com/components/grid?version=2" className="block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-300 transform hover:text-primary">
                                    version 2
                                </a>
                                <a href="https://tailwindcomponents.com/components/grid?version=1" className="block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-300 transform hover:text-primary">
                                    version 1
                                </a>
                                <a href="https://tailwindcomponents.com/components/grid?version=0" className="block px-4 py-2 text-sm text-gray-700 capitalize transition-colors duration-300 transform hover:text-primary">
                                    Beta version
                                </a>
                                <div id="extwaiokist" style={{ display: "none" }} v="dbhdf" q="36f0aeaa" c="53.89" i="64" u="12.90" s="06182305" sg="svr_undefined-ga_06182305-bai_06032321" d="1" w="false" e="" a="2" m="BMe=" vn="9zsmd">
                                    <div id="extwaigglbit" style={{ display: "none" }} v="dbhdf" q="36f0aeaa" c="53.89" i="64" u="12.90" s="06182305" sg="svr_undefined-ga_06182305-bai_06032321" d="1" w="false" e="" a="2" m="BMe="></div>
                                </div>
                            </div>
                        </div>
                    </div> */}


                </div>

                <style>
                    {`
  #journal-scroll {
    height: 75px;
    overflow-y: auto;
  }

  #journal-scroll::-webkit-scrollbar {
    height: 2px;
    width: 16px;
  }

  #journal-scroll::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 41px;
  }

  #journal-scroll:hover::-webkit-scrollbar-thumb {
    background: ; /* Change the scrollbar color to red on hover */
  }

  #journal-scroll::-webkit-scrollbar-track {
    background: transparent;
  }
`}
                </style>


                {/* <div class="relative hide-with-banner"><a href="https://material-tailwind.com?ref=tailwindcomponents" target="_blank" class="flex flex-col w-full h-full py-5"><div class="z-10 flex-col items-start justify-center w-1/2 px-6 xl:px-8"><img src="/images/ap/react-html.png" class="w-20 mb-4"/> <p class="text-xs text-white">React Components Library</p> <p class="mb-5 font-sans text-2xl font-bold text-white">
                    Material Tailwind
                </p> <div class="mb-3"><span class="h-8 px-4 py-3 font-sans text-xs font-bold text-white bg-blue-500 rounded-lg shadow-md">Get Started</span></div></div> <img src={AiBackground} class="absolute bottom-0 left-0 object-cover w-full h-full overflow-hidden rounded-md"/>
                </a>
                <div className='absolute bottom-0 bg-gradient-to-r from-fuchsia-700/30 to-violet-700'></div>

                </div> */}
















                {/* <HomeTeam /> */}


                {loading ? (
                    <div className="flex justify-center items-center h-screen">
                        {/* <div className="w-8 h-8 border-2 border-t-2 border-gray-300 rounded-full animate-spin"></div> */}
                        <>
                            <div className="bg-transparent">
                                <div className="px-4 py-12 bg-transparent">
                                    <div className="mx-auto flex justify-center">
                                        <div className="relative">
                                            <div className="w-[160px] h-[160px] border border-sky-500 rounded-full" />
                                            <div className="w-[140px] h-[140px] border border-sky-500 rounded-full absolute top-2.5 right-2.5" />
                                            <div>
                                                <svg
                                                    className="absolute top-[22px] right-[26px] animate-spin infinite"
                                                    width={113}
                                                    height={113}
                                                    viewBox="0 0 113 113"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M56.7631 110.374C46.061 110.374 35.5993 107.2 26.7008 101.255C17.8023 95.3088 10.8668 86.8579 6.77128 76.9704C2.67576 67.083 1.60419 56.2031 3.69207 45.7066C5.77994 35.2102 10.9335 25.5686 18.501 18.001C26.0686 10.4335 35.7102 5.27994 46.2066 3.19207C56.7031 1.10419 67.583 2.17576 77.4704 6.27128C87.3579 10.3668 95.8088 17.3023 101.755 26.2008C107.7 35.0993 110.874 45.561 110.874 56.2631"
                                                        stroke="#0ea5e9"
                                                        strokeWidth={2}
                                                        strokeLinecap="round"
                                                        strokeDasharray="16 16"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <p className="text-center text-gray-600 text-base mt-4">
                Your Request Is Being Loaded, Please Wait
              </p> */}
                                </div>
                            </div>
                        </>
                    </div>



                ) : (
                    <div className="grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-2 xl:grid-cols-3 md:mt-20 mb-16">

                        {data.map((item, index) => (


                            // <div class="flex justify-between">
                            //     <div class="flex flex-col h-full max-w-lg mx-auto bg-gray-800 rounded-lg">
                            //         <img
                            //             class="rounded-lg rounded-b-none"
                            //             src="https://th.bing.com/th/id/R.09c5c952bd016a7e770499c5bcb7e279?rik=62OY52A4HQugcA&riu=http%3a%2f%2f1.bp.blogspot.com%2f-SdFKKChF0u4%2fVRxX8Sr2z8I%2fAAAAAAAABbw%2f3GtaOfkIPCg%2fs1600%2fPizzaMall.jpg&ehk=IYGG1%2fPosTA39kKfqOKxYtFR6fE9lWTqhxhgMqZP0cM%3d&risl=&pid=ImgRaw&r=0"
                            //             alt="thumbnail"
                            //             loading="lazy"
                            //         />
                            //         <div class="flex justify-between -mt-4 px-4">
                            //             <span
                            //                 class="inline-block ring-4 bg-red-500 ring-gray-800 rounded-full text-sm font-medium tracking-wide text-gray-100 px-3 pt-0.5"
                            //             >Tech</span
                            //             >
                            //             <span
                            //                 class="flex h-min space-x-1 items-center rounded-full text-gray-400 bg-gray-800 py-1 px-2 text-xs font-medium"
                            //             >
                            //                 <svg
                            //                     xmlns="http://www.w3.org/2000/svg"
                            //                     class="h-5 w-5 text-blue-500"
                            //                     fill="none"
                            //                     viewBox="0 0 24 24"
                            //                     stroke="currentColor"
                            //                 >
                            //                     <path
                            //                         stroke-linecap="round"
                            //                         stroke-linejoin="round"
                            //                         stroke-width="2"
                            //                         d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                            //                     />
                            //                 </svg>
                            //                 <p class="text-blue-500 font-semibold text-xs">
                            //                     6 Mins
                            //                 </p>
                            //             </span>
                            //         </div>
                            //         <div class="py-2 px-4">
                            //             <h1
                            //                 class="text-xl font-medium leading-6 tracking-wide text-gray-300 hover:text-blue-500 cursor-pointer"
                            //             >
                            //                 <a href="#">JIT Mode - A faster, more powerful, on-demand engine for tailwind</a>
                            //             </h1>
                            //         </div>
                            //         <div class="px-4 space-y-2">
                            //             <p class="text-gray-400 font-normal leading-5 tracking-wide">
                            //                 Tailwind CSS v2.1 introduces a new just-in-time compiler for Tailwind CSS that generates your styles on-demand...
                            //             </p>
                            //             <router-link
                            //                 to="blog/detail"
                            //                 class="font-bold hover:text-blue-400 text-gray-100"
                            //             >read more...</router-link
                            //             >
                            //         </div>
                            //         <div class="flex flex-row items-end h-full w-full px-4 mt-4">
                            //             <div class="flex border-t border-gray-700 w-full py-4">
                            //                 <div
                            //                     class="flex items-center space-x-3 border-r border-gray-700 w-full"
                            //                 >
                            //                     <img
                            //                         class="object-cover w-8 h-8 border-2 border-white rounded-full"
                            //                         src="https://storageapi.fleek.co/kamaludin21-team-bucket/portfolio/avatar.jpg"
                            //                         alt="profile users"
                            //                         loading="lazy"
                            //                     />
                            //                     <div class="">
                            //                         <p class="text-sm font-semibold tracking-wide text-gray-200">
                            //                             Author
                            //                         </p>
                            //                         <p class="text-xs font-light tracking-wider text-gray-300">
                            //                             2 Hours ago
                            //                         </p>
                            //                     </div>
                            //                 </div>
                            //                 <div class="flex items-center flex-shrink-0 px-2">
                            //                     <div class="flex items-center space-x-1 text-gray-400">
                            //                         <svg
                            //                             xmlns="http://www.w3.org/2000/svg"
                            //                             class="h-5 w-5"
                            //                             fill="none"
                            //                             viewBox="0 0 24 24"
                            //                             stroke="currentColor"
                            //                         >
                            //                             <path
                            //                                 stroke-linecap="round"
                            //                                 stroke-linejoin="round"
                            //                                 stroke-width="2"
                            //                                 d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                            //                             />
                            //                         </svg>
                            //                         <p class="font-medium">10</p>
                            //                     </div>
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     </div>
                            // </div>



                            <div className="duration-300 hover:-translate-y-2 -translate-y-1 rounded-lg shadow-[0px_0px_15px_rgba(0,0,0,0.1)] pb-4 bg-white"
                                style={{
                                    backgroundImage: `url(https://transknit.shop${item.image})`,
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'top 20px center',
                                    backgroundSize: '60%',
                                    backgroundRepeat: 'no-repeat'

                                }}>

                                <div className="flex items-center space-x-2 text-gray-900 absolute z-10 bottom-4 right-5">
                                    <div className="flex items-center space-x-1 bg-yellow-500 px-2 rounded-full">
                                        {item.price} Rs.
                                    </div>
                                </div>




                                <div>


                                    <div className="relative z-10">
                                        <button onClick={() => setCurrentProjectId(item.id, item.title)} className="relative block overflow-hidden aspect-w-16 aspect-h-10">
                                            <div className="relative h-52 w-full">
                                                {/* <img
                                                    src={`https://www.cafepiala.shop${item.image}`}
                                                    className="rounded-tl-lg rounded-tr-lg object-contain"
                                                    alt="Post"
                                                /> */}
                                                <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-50"></div>
                                            </div>
                                        </button>






                                        <div className="flex items-center space-x-2 text-gray-900 -mb-14 absolute bottom-20 right-5">
                                            <button className="flex items-center space-x-1">
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                            <path
                                                fillRule="evenodd"
                                                d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg> */}
                                                <style>{`@import url(https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.min.css);`}</style>

                                                <button onClick={() => AddtoCart(item.id)} className="rounded-full duration-300 hover:translate-y-2">
                                                    {/* <div className="flex flex-row justify-between items-center space-x-2">
<img src="https://www.iconpacks.net/icons/2/free-shopping-cart-icon-2029-thumb.png" className="h-6 w-6"/><span className="text-sm">Add to Cart</span>
</div> */}
                                                    <div class="flex flex-row justify-between items-center space-x-2">
                                                        <button class="bg-yellow-300 opacity-75 hover:opacity-100 text-yellow-900 hover:text-gray-900 rounded-full px-10 py-2 font-semibold"><i class="mdi mdi-cart -ml-2 mr-2"></i> add to cart</button>
                                                    </div>
                                                </button>

                                            </button>
                                            {/* <div className="flex items-center space-x-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                                <path
                                                    fillRule="evenodd"
                                                    d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                            <span className="text-sm font-medium">{item.downloads}</span>
                                        </div> */}
                                        </div>

                                        <div className="absolute top-1 left-3 mt-2 mr-2 h-6 flex items-center justify-center opacity-70">
                                            <a className="text-xs font-bold text-white">
                                                {item?.quantity !== undefined ? (
                                                    item.quantity > 0 ? (
                                                        <span>

                                                        </span>
                                                    ) : (
                                                        <span className="bg-red-500 text-white rounded-md w-auto p-2">Sold Out</span>
                                                    )
                                                ) : (
                                                    <div></div>
                                                )}
                                            </a>
                                        </div>

                                        <div className="absolute top-1 right-1 mt-2 mr-2 bg-gray-100 text-black rounded-full w-auto p-2 h-6 flex items-center justify-center opacity-70">
                                            <a className="text-xs font-bold text-black">{getTimeDifference(item.timestamp, item.time)}</a>
                                        </div>



                                    </div>




                                </div>

                                <div className="absolute inset-0 bg-gradient-to-t from-white opacity-100 to-transparent rounded-b-lg mt-36">


                                </div>
                                <div className="absolute inset-0 bg-gradient-to-t from-white opacity-100 to-transparent rounded-b-lg mt-36">


                                </div>



                                <div onClick={() => setCurrentProjectId(item.id, item.title)} className="relative z-10 px-6 pb-4 cursor-pointer bg-transparent rounded-b-lg"
                                    style={{
                                        // backdropFilter: 'blur(3px)', // Adjust the blur amount as needed
                                        backgroundColor: 'transparent', // Adjust the background color and opacity as needed
                                    }}>




                                    <h3 className="text-gray-800 w-1/2 text-md font-bold tracking-widest pb-2 mb-6 pt-8 border-b border-red-500">

                                        {item.tag ? (
                                            item.tag.toUpperCase()
                                        ) : (
                                            <div className="flex flex-col mb-2">
                                                <div className="animate-pulse bg-gray-300 h-5 w-32 rounded" />
                                            </div>

                                        )}

                                    </h3>

                                    <a

                                        className="font-semibold text-gray-700 transition-colors duration-300 text-xl hover:text-primary hover:text-primary"
                                    >
                                        {item.title}
                                    </a>

                                    <br></br>


                                    <div className="mt-2 mb-8">
                                        <small className="leading-5 text-gray-600 text-md">
                                            {/* {
                                            ((<Markup content={item.description.replace(/\\/g, '')} />).props.content).length > 242
                                                ? <Markup content={((<Markup content={item.description.replace(/\\/g, '')} />).props.content).substring(0, 242).split(' ').slice(0, -1).join(' ') + ' ...'} />
                                                : <Markup content={item.description.replace(/\\/g, '')} />
                                        } */}
                                            {item.description}
                                        </small>
                                    </div>








                                </div>


                            </div>



                        ))}

                    </div>
                )}

                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />


            </div>

            <Footer />

            <ToastContainer position="top-right" autoClose={3000} hideProgressBar />


        </div>
    );
};

export default EmployeeData;