import React, { Component, createRef } from 'react';
import { FaCheckCircle, FaEdit, FaWindowClose } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ProductCategoryList from './ProductCategoryList';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { modules, formats } from "../js/EditorToolbar";
import { EditorToolbar } from '../js/EditorToolbar';

class UpdateProduct extends Component {

    
    constructor(props) {
        super(props);
        this.state = {
            ownerName: '', // title
            cardNumber: '', // tag
            expiryDate: '', // price
            cvc: '', // description
            showModal: false,
            errors: {}, // Store errors for each field
            submission_open: true,
            added: 2,
            image: null,
            preview: null,
            error: 0,
            selectedCategory: '',
            topic: '',
            productId: props.productId, // Pass the product ID as a prop
        };

         // Create a ref for the modal button
         this.modalRef = createRef();
    }

    componentDidMount() {
           // Expose modalHandler through the ref
           if (this.modalRef.current) {
            this.modalRef.current.modalHandler = this.modalHandler;
        }
    }

    fetchProductData = async () => {
        try {
          const response = await fetch(
            `https://transknit.shop/database/get-product.php?id=${encodeURIComponent(localStorage.getItem('www.transknit.shop-updateproductid'))}`
          );
          const json = await response.json();
        
          if (true) {
            
            const { title, tag, price, category, description, details, image, quantity } = json[0];
            this.setState({
                ownerName: title,
                cardNumber: tag,
                expiryDate: price,
                selectedCategory: category,
                topic: description,
                cvc: details,
                preview: "https://transknit.shop" + image,
                quantity: quantity,
            });

            console.log(json[0].title);
        } else {
            // Handle error
            toast.error('Failed to fetch product data');
        }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

    

    handleChange = (e) => {
        const { id, value } = e.target;
        if (id === 'ownerName') {
            if (value.length <= 1000) {
                this.setState({ [id]: value });
            } else {
                this.setState({ [id]: value.slice(0, 100) });
            }
        } else if (id === 'topic') {
            if (value.length <= 1000) {
                this.setState({ [id]: value });
            } else {
                this.setState({ [id]: value.slice(0, 25) });
            }
        } else if (id === 'cardNumber') {
            if (value.length <= 1000) {
                this.setState({ [id]: value });
            } else {
                this.setState({ [id]: value.slice(0, 25) });
            }
        } else if (id === 'expiryDate') {
            if (value.length <= 1000) {
                this.setState({ [id]: value });
            } else {
                this.setState({ [id]: value.slice(0, 170) });
            }
        } else {
            this.setState({ [id]: value });
        }
    };

    updateProductData = async () => {
        console.log("nidnecn updating 1");
       

            console.log("nidnecn updating 2");

            try {

                console.log("nidnecn updating 3");


                const formData = new FormData();
                formData.append('id', localStorage.getItem('www.transknit.shop-updateproductid'));
                formData.append('title', this.state.ownerName);
                formData.append('image', this.state.image);
                formData.append('price', this.state.expiryDate);
                formData.append('category', this.state.selectedCategory);
                formData.append('description', this.state.topic);
                formData.append('tag', this.state.cardNumber);
                formData.append('details', this.state.cvc);
                formData.append('quantity', this.state.quantity);

                const response = await fetch('https://transknit.shop/database/update-product.php', {
                    method: 'POST',
                    body: formData,
                });

                const json = await response.json();

                if (json.success === true) {
                    // Updated successfully
                    this.setState({ submission_open: false, added: 1 });
                } else {
                    // Failed to update
                    this.setState({ added: 0 });
                }
            } catch (error) {
                console.error('Error updating product data:', error);
            }
    
    };

    handleCategorySelect = (category) => {
        this.setState({ selectedCategory: category });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.state.submission_open === true) {
            const { ownerName, cardNumber, expiryDate, topic } = this.state;
            const errors = {};

            // Validate each field
            if (topic.trim() === '') {
                errors.topic = 'Owner Name is required';
            }
            if (ownerName.trim() === '') {
                errors.ownerName = 'Owner Name is required';
            }
            if (expiryDate.trim() === '') {
                errors.expiryDate = 'Expiry Date is required';
            }

            if (Object.keys(errors).length > 0) {
                // If there are errors, update the state with the error messages
                this.setState({ errors });
            } else {
                this.updateProductData();
            }
        }
    };

    handleSuccessfulUpdate = () => {
        this.props.reloadPage();
    };

    modalHandler = () => {
        this.fetchProductData();
        this.handleSuccessfulUpdate();

        this.setState({
            submission_open: true,
            ownerName: '',
            cardNumber: '',
            expiryDate: '',
            cvc: '',
            topic: '',
            added: 2,
            image: null,
            preview: null,
            quantity: '',
        });

        this.setState((prevState) => ({
            showModal: !prevState.showModal,
        }));
    };

    handleImageChange = (e) => {
        const file = e.target.files[0];
        const fileSize = file.size / 1024; // Size in KB

        if (fileSize > 2048) {
            // Reset the file input value and show an error message
            e.target.value = null;
            toast(
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FaWindowClose className="mr-2 text-red-600" />
                    <span>Project picture max size: 2 MB</span>
                </div>,
                {
                    className: 'custom-toast-success', // Add a custom CSS class for styling
                }
            );
        } else {
            // Proceed with setting the image and preview
            console.log('image: ' + file);
            this.setState({ image: file, preview: URL.createObjectURL(file), error: 0 });
        }
    };

    handleChangeCvc = (value) => {
        this.setState({ cvc: value });
    };

    render() {
        const { ownerName, cardNumber, expiryDate, cvc, showModal, errors, image, preview, error, topic, quantity } = this.state;
        const { selectedCategory } = this.state;

        return (
            <div>
                {this.state.showModal && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            alignItems: 'right',
                            justifyContent: 'right',
                            zIndex: 9999,
                            overflow: 'auto',
                        }}
                    >
                        <div
                        // style={{
                        //     maxHeight: '80vh',
                        //     maxWidth: '80%',
                        //     backgroundColor: '#fff',
                        //     padding: '20px',
                        //     borderRadius: '8px',
                        //     overflow: 'auto',
                        // }}
                        >
                            <div className="pt-16 pb-48 bg-gray-700 bg-opacity-50 transition duration-150 ease-in-out z-10 absolute top-0 right-0 left-0" id="modal">
                                <div role="alert" className="container mx-auto w-11/12 md:w-2/3">
                                    <div className="relative py-8 px-5 md:px-10 bg-gray-100 shadow-md rounded border border-gray-400">
                                        <div className="w-full flex justify-start text-gray-600 mb-3">

                                            {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-wallet"
                    width={52}
                    height={52}
                    viewBox="0 0 24 24"
                    strokeWidth={1}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12" />
                    <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4" />
                  </svg> */}

                                            {/* <FaRegFilePowerpoint className="h-12 w-12" /> */}
                                            {this.state.added === 1 && (
                                                <p className="text-green-500 mt-4 rounded pl-2 pr-2 pt-1 border border-green-500">Successfully updated</p>
                                            )
                                            }

                                            {this.state.added === 0 && (
                                                <p className="text-red-500 mt-4 ml-5 rounded pl-2 pr-2 pt-1 border border-red-500">Failed to add</p>
                                            )
                                            }

                                        </div>
                                        {/* <div className=" flex justify-between items-center mb-16">
                                            <h2 className=" lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800 font-semibold"></h2> */}
                                       
                                        {/* </div> */}
                                        {/* <h1 className="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4">Enter Project Details</h1> */}
                                        <form onSubmit={this.handleSubmit}>



                                            <div className="container mx-auto">
                                                <form onSubmit={this.handleSubmit} className="text-center">
                                                    <label htmlFor="file-input" className="cursor-pointer">
                                                        <div className={`sm:w-5/4 md:w-5/6 lg:w-1/2 h-60 mx-auto my-4 border-2 border-dashed rounded-lg flex items-center justify-center ${error === 0 ? 'text-gray-500 border-gray-500' : 'text-red-500 border-red-500'} overflow-hidden`}>
                                                            {preview ? (
                                                                <img
                                                                    src={preview}
                                                                    alt="Preview"
                                                                    className="object-contain w-full h-full"
                                                                    style={{ objectPosition: 'top' }}
                                                                />
                                                            ) : (
                                                                <svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M22.5 12H22.515" stroke={error === 0 ? '#94A3B8' : '#FF0000'} strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M25.5 6H10.5C8.01472 6 6 8.01472 6 10.5V25.5C6 27.9853 8.01472 30 10.5 30H25.5C27.9853 30 30 27.9853 30 25.5V10.5C30 8.01472 27.9853 6 25.5 6Z" stroke={error === 0 ? '#94A3B8' : '#FF0000'} strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M6 22.4999L12 16.4999C12.6841 15.8417 13.4601 15.4951 14.25 15.4951C15.0399 15.4951 15.8159 15.8417 16.5 16.4999L24 23.9999" stroke={error === 0 ? '#94A3B8' : '#FF0000'} strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M21 20.9999L22.5 19.4999C23.1841 18.8417 23.9601 18.4951 24.75 18.4951C25.5399 18.4951 26.3159 18.8417 27 19.4999L30 22.4999" stroke={error === 0 ? '#94A3B8' : '#FF0000'} strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                // <span className="text-4xl">+</span>
                                                            )}
                                                        </div>
                                                    </label>
                                                    <input
                                                        id="file-input"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.handleImageChange}
                                                        className="hidden"
                                                    />
                                                    {/* <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Upload Image
        </button> */}
                                                </form>
                                            </div>

                                            
<label htmlFor="topic" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                                                Tag
                                            </label>
                                            <input
                                                id="cardNumber"
                                                className={`mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border ${errors.topic ? 'border-red-500' : ''
                                                    }`}
                                                style={{ fontWeight: 'normal' }}
                                                placeholder=""
                                                value={cardNumber}
                                                onChange={this.handleChange}
                                            />

                                           

                                            <label htmlFor="ownerName" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                                                Title
                                            </label>
                                            <input
                                                id="ownerName"
                                                className={`mb-5 mt-2 text-gray-800 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border ${errors.ownerName ? 'border-red-500' : ''
                                                    }`}
                                                style={{ fontWeight: 'normal', fontSize: '1rem' }}
                                                placeholder=""
                                                value={ownerName}
                                                onChange={this.handleChange}
                                            />

<label htmlFor="topic" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                                                Description
                                            </label>
                                            <input
                                                id="topic"
                                                className={`mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border ${errors.topic ? 'border-red-500' : ''
                                                    }`}
                                                style={{ fontWeight: 'normal' }}
                                                placeholder=""
                                                value={topic}
                                                onChange={this.handleChange}
                                            />

                                            
<label htmlFor="topic" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                                                Details
                                            </label>

<div className="text-editor pt-2 pb-5">
                                                <EditorToolbar />
                                                <ReactQuill
                                                id="cvc"
                                                    theme="snow"
                                                    value={cvc}
                                                    onChange={this.handleChangeCvc}
                                                    placeholder={"Add Product Details Here..."}
                                                    modules={modules}
                                                    formats={formats}
                                                    className="border-2 border-gray-300 h-80"
                                                />
                                            </div>

                                            <label htmlFor="expiryDate" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                                                price (Rs.)
                                            </label>
                                            <div className="relative mb-5 mt-2">
                                                <div className="absolute right-0 text-gray-600 flex items-center pr-3 h-full cursor-pointer">
                                                    {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-calendar-event"
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <rect x={4} y={5} width={16} height={16} rx={2} />
                        <line x1={16} y1={3} x2={16} y2={7} />
                        <line x1={8} y1={3} x2={8} y2={7} />
                        <line x1={4} y1={11} x2={20} y2={11} />
                        <rect x={8} y={15} width={2} height={2} />
                      </svg> */}
                                                </div>
                                                <input
                                                    id="expiryDate"
                                                    type="number"
                                                    className={`text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border ${errors.expiryDate ? 'border-red-500' : ''
                                                        }`}
                                                    placeholder=""
                                                    value={expiryDate}
                                                    onChange={this.handleChange}
                                                />
                                            </div>


                                            <label htmlFor="expiryDate" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                                                Quantity
                                            </label>
                                            <div className="relative mb-5 mt-2">
                                                <div className="absolute right-0 text-gray-600 flex items-center pr-3 h-full cursor-pointer">
                                                    {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-calendar-event"
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <rect x={4} y={5} width={16} height={16} rx={2} />
                        <line x1={16} y1={3} x2={16} y2={7} />
                        <line x1={8} y1={3} x2={8} y2={7} />
                        <line x1={4} y1={11} x2={20} y2={11} />
                        <rect x={8} y={15} width={2} height={2} />
                      </svg> */}
                                                </div>
                                                <input
                                                    id="quantity"
                                                    type="number"
                                                    className={`text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border ${errors.expiryDate ? 'border-red-500' : ''
                                                        }`}
                                                    placeholder=""
                                                    value={quantity}
                                                    onChange={this.handleChange}
                                                />
                                            </div>

                                            {/* <label htmlFor="cardNumber" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                    Png image (link)
                  </label>
                  <div className="relative mb-5 mt-2">
                    <div className="absolute text-gray-600 flex items-center px-4 border-r h-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-credit-card"
                        width={20}
                        height={20}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <rect x={3} y={5} width={18} height={14} rx={3} />
                        <line x1={3} y1={10} x2={21} y2={10} />
                        <line x1={7} y1={15} x2="7.01" y2={15} />
                        <line x1={11} y1={15} x2={13} y2={15} />
                      </svg>
                    </div>
                    <input
                      id="cardNumber"
                      className={`text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-16 text-sm border-gray-300 rounded border ${errors.cardNumber ? 'border-red-500' : ''
                        }`}
                      placeholder="e.g., https://png.com/image.png"
                      value={cardNumber}
                      onChange={this.handleChange}
                    />
                  </div> */}


                                            <ProductCategoryList onCategorySelect={this.handleCategorySelect} />



                                            {/* <label htmlFor="cvc" className="text-gray-800 text-sm font-bold leading-tight tracking-normal">
                                                Description
                                            </label> */}

                                            {/* <div className="overflow-y-auto h-34 mb-5 mt-2">
                        <textarea
                          className={`mb-8 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-32 flex items-center pl-3 text-sm border-gray-300 rounded border ${errors.cvc ? 'border-red-500' : ''
                            }`}
                          id="cvc"
                          placeholder=""
                          value={cvc}
                          onChange={this.handleChange}
                        // onKeyDown={(e) => {
                        //   if (e.key === "Enter") {
                        //     e.preventDefault();
                        //   }
                        // }}

                        />
                      </div> */}
                                            {/* <ReactQuill
                                                id="cvc"
                                                value={cvc}
                                                onChange={this.handleChangeCvc}
                                                className={`mb-16 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-32 text-sm  rounded border ${errors.cvc ? 'border-red-500' : ''}`}
                                            /> */}

                                            <div className="text-editor">
                                                {/* <EditorToolbar />
                                                <ReactQuill
                                                    theme="snow"
                                                    value={cvc.replace(/\\r\\n/g, '</br>').replace(/\\/g, '')}
                                                    onChange={this.handleChangeCvc}
                                                    placeholder={"Write something awesome..."}
                                                    modules={modules}
                                                    formats={formats}
                                                    className="border-2 border-gray-300 h-80"
                                                /> */}
                                            </div>

                                            <div className="flex items-center justify-start w-full mt-8">
                                                <button
                                                    className="focus:outline-none bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
                                                    onClick={this.modalHandler}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    className="focus:outline-none ml-3 transition duration-150 ease-in-out hover:bg-green-600 bg-green-700 rounded text-white px-8 py-2 text-sm"
                                                    onClick={this.handleSubmit}
                                                >
                                                    Submit
                                                </button>
                                                {this.state.added === 1 && (
                                                    <p className="text-green-500 ml-3 rounded p-2 border border-green-500"><FaCheckCircle /></p>
                                                )
                                                }
                                            </div>
                                        </form>
                                        <div
                                            className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out"
                                            onClick={this.modalHandler}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                aria-label="Close"
                                                className="icon icon-tabler icon-tabler-x"
                                                width={20}
                                                height={20}
                                                viewBox="0 0 24 24"
                                                strokeWidth="2.5"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" />
                                                <line x1={18} y1={6} x2={6} y2={18} />
                                                <line x1={6} y1={6} x2={18} y2={18} />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )}




                <div className="w-full flex justify-center py-2 mr-2" id="button">
                    <button
                        className="focus:outline-none mx-auto transition duration-150 ease-in-out rounded text-white py-2 text-2xl"
                        ref={this.modalRef}
                    >
                       
                    </button>
                </div>

                <ToastContainer position="top-right" autoClose={3000} hideProgressBar />


            </div>


        );
    }
}

export default UpdateProduct;
