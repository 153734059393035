import React, { useEffect } from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";

import transknit from '../images/transknit.png'

const About = () => {

    useEffect(() => {
       
        
    
        window.scrollTo(0, 0); // Scroll to top when component mounts

    
    
      }, []);


    return (
        <div class=" bg-white">
            <div>
                <Header />
                <div class="container m-auto px-6 text-gray-100 md:px-12 xl:px-6 lg:py-16 pb-16 2xl:px-16">
                    <div class=" md:space-y-0 lg:flex space-x-4 lg:items-center lg:gap-12">
                        <div class="lg:w-4/12">
                            <img src={transknit} alt="image" loading="lazy" width="" height="" />
                        </div>
                        <div class="lg:w-8/12">
                        <div>
                            <h2 class="text-2xl text-gray-950 font-bold md:text-4xl">TransKnit Attire</h2>
                            <p class="mt-6 text-gray-800">At Transknit Attire, we pride ourselves on offering an exquisite collection of designer clothes that blend traditional craftsmanship with contemporary fashion. Our curated selection of attire is designed to make a statement, whether it's for a special occasion or everyday elegance. We believe that clothing is not just about covering the body but expressing one's personality, culture, and style. Our team of skilled artisans and designers work tirelessly to bring you the finest fabrics, intricate embroideries, and unique designs that set you apart from the crowd.</p>
                            <p class="mt-4 text-gray-800">We are committed to sustainability and ethical fashion practices. Each piece in our collection is crafted with care, ensuring that it not only looks beautiful but also stands the test of time. Our dedication to quality and detail is evident in every stitch, making Transknit Attire a trusted name in the world of fashion. Join us in celebrating the art of clothing and experience the luxury and sophistication that our designs offer.</p>
                        </div>

                        <div class="mt-20">
                            <h2 class="text-2xl text-gray-950 font-bold md:text-4xl">TransKnit Home</h2>
                            <p class="mt-6 text-gray-800">Transknit Home is your destination for handcrafted home decoration pieces that bring warmth and character to any space. Our collection features a wide range of artisanal products, from intricately carved wooden decor to beautifully woven textiles, all designed to add a touch of elegance to your home. Each item is a testament to the skill and creativity of our artisans, who pour their heart and soul into every piece they create.</p>
                            <p class="mt-4 text-gray-800">We believe that home is where the heart is, and our mission is to help you make your home a true reflection of your unique taste and personality. At Transknit Home, we are passionate about preserving traditional crafting techniques while infusing them with modern design elements. Our commitment to sustainability and ethical sourcing ensures that every purchase you make supports communities of artisans and promotes a more sustainable way of living. Explore our collection and discover the beauty of handcrafted home decor that tells a story of heritage, craftsmanship, and creativity.</p>
                        </div>
                        </div>
                        
                    </div>
                </div>
            </div>
           
            <Footer />
        </div>
    );
}

export default About;