import React, { useState } from "react";
import AdminHome from "../partials/AdminHome";
import AdminHeader from "../partials/AdminHeader";
import Footer from "../partials/Footer";
import AdminProducts from "../partials/AdminProducts";
import AdminOrders from "../partials/AdminOrders";

const Admin = () => {

    const [activeComponent, setActiveComponent] = useState("AdminHome");

    const [activeButton, setActiveButton] = useState('AdminHome');

    const handleComponentChange = (componentName) => {

        setActiveComponent(componentName);
        setActiveButton(componentName);
    };

    const renderActiveComponent = () => {
        switch (activeComponent) {
            case "AdminHome":
                return <AdminHome />;
            case "AdminOrders":
                return <AdminOrders />;
            case "AdminProducts":
                return <AdminProducts />;
            default:
                return null;
        }
    };

    const homeButton = () => {
        // Do something when the button in DevHome is clicked
        handleComponentChange('AdminHome')
        // You can perform any other actions or state updates here
    };

    const ordersButton = () => {
        // Do something when the button in DevHome is clicked
        handleComponentChange('AdminOrders')
        // You can perform any other actions or state updates here
    };

    const productsButton = () => {
        // Do something when the button in DevHome is clicked
        handleComponentChange('AdminProducts')
        // You can perform any other actions or state updates here
    };

    return (

        <div className="bg-gray-950 min-h-screen">
            <div className="min-h-screen">
                <AdminHeader Home={homeButton} Orders={ordersButton} Products={productsButton} />
                {renderActiveComponent()}
            </div>
            <Footer />
        </div>

    );
}

export default Admin;