import React from "react";
import { FaCartArrowDown } from "react-icons/fa";
import { Link } from 'react-router-dom'
import transknit from '../images/transknit-footer.png'


function AdminHeader ({ Home, Orders, Products}) {

    return(
        <header className="font-poppins">
        <nav class="bg-transparent bg-opacity-0 px-4 lg:px-6 py-5">
            <div class="flex flex-col lg:flex-row justify-between items-center mx-auto max-w-screen-xl">
                <Link href="/" class="flex items-center">
                    <img src={transknit} class="mr-3 h-24 sm:h-36" alt="Flowbite Logo" />                </Link>
          
                <div class="justify-center items-center w-full flex lg:w-auto lg:order-1" id="mobile-menu-2">
                    <ul class="flex mt-10 lg-mt-4 font-normal flex-row lg:space-x-8 lg:mt-0 text-sm overflow-x-auto scrollbar-visible pb-3">
                        {/* <li>
                            <a href="#" class="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white" aria-current="page">Home</a>
                        </li> */}
                        <li>
                            <button href="/" class="block py-2 pr-4 pl-3 text-gray-200  hover:bg-gray-50 lg:hover:bg-transparent lg:border-0  lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" onClick={() => Home()}>Home</button>
                        </li>
                        <li>
                            <button href="/menu" class="block py-2 pr-4 pl-3 text-gray-200  hover:bg-gray-50 lg:hover:bg-transparent lg:border-0  lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" onClick={() => Orders()}>Orders</button>
                        </li>
                        <li>
                            <button href="#" class="block py-2 pr-4 pl-3 text-gray-200  hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" onClick={() => Products()}>Products</button>
                        </li>

                       

                    
                        {/* <li>
                            <a href="#" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Team</a>
                        </li>
                        <li>
                            <a href="#" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Contact</a>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    </header>
    );
}

export default AdminHeader;